<script>
import { mapActions, mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
    computed: mapGetters(["usersSameCompany"]),
    props: {
        user: {
            type: Object
        },
        noPdf: {
            type: Boolean
        },
        customProcena: {
            type: Array
        },
    },
    data() {
        return {
            selectedProcena: null,
            activeProcena: null,
            year: null,
            location: null,
            position: null,
            answers: [],
            savedSuccessfully: false,
            saveFailed: false,
            savedProcenaExists: false,
            saveFailedText: null,
            finished: false,
            showHtmlPdf: false,
            total: null,
            totalPercent: null,
        }
    },
    methods: {
        ...mapActions(["getCollegues", 'customProcenaSaveAnswers']),
        showPdf() {
            this.showHtmlPdf = true;
            this.getTotal();
        },
        async submit() {
            this.answers = [];
            if (this.user.customProcenaSchema) {
                this.user.customProcenaSchema.data.forEach(ele => {
                    ele.name == this.selectedProcena ? this.savedProcenaExists = true : null
                })
            }
            if (!this.savedProcenaExists) {
                this.activeProcena.questions.forEach((element) => {
                    if (element.isSingleCheck) {
                        this.answers.push(element.isChecked)
                    } else if (element.isComboAnswer) {
                        this.answers.push({ text: element.isCombo.text, answers: element.isCombo.isChecked })
                    }
                    else if (element.isTextAnswer) {
                        this.answers.push(element.textAnswer)
                    }
                })
                this.finished = true;
                let data = {
                    finished: this.finished,
                    answers: this.answers,
                    name: this.selectedProcena,
                    year: this.year,
                    location: this.location,
                    position: this.position,
                    id: this.user._id
                };
                let res = await this.customProcenaSaveAnswers(data)
                if (res) {
                    this.validation = `Sačuvani podaci za korisnika ${this.user.name}`;
                    this.savedSuccessfully = true;
                    this.mainName = null;
                    setTimeout(() =>
                        this.savedSuccessfully = false, 5000
                    );
                } else {
                    this.validation = `Neuspelo povezivanje sa bazom (code: PEA_182)`;
                    this.saveFailed = true;
                    setTimeout(() =>
                        this.saveFailed = false, 5000
                    );
                }
            } else {
                this.saveFailedText = `Već postoje sačuvani podaci za ovu procenu!`;
                this.saveFailed = true;
                setTimeout(() =>
                    this.saveFailed = false, 5000
                );
            }
            this.savedProcenaExists = false;
        },
        generateProcena(procena) {
            this.showHtmlPdf = false;
            this.customProcena.forEach((ele) => {
                if (ele.name == procena) {
                    this.activeProcena = ele

                    this.activeProcena.questions.forEach((element) => {
                        if (element.isSingleCheck) {
                            element.isChecked = [];
                            element.answers.forEach((answ) => {
                                element.isChecked.push({ checked: false, answer: answ })
                            })
                        } else if (element.isComboAnswer) {
                            element.isCombo = { text: null, isChecked: [] };
                            element.isCombo.text = null,
                                element.answers.forEach((answ) => {
                                    element.isCombo.isChecked.push({ checked: false, answer: answ })
                                })
                        }
                        else if (element.isTextAnswer) {
                            element.textAnswer = null;
                        }
                    })
                }
            });
            if (this.user.customProcenaSchema) {
                this.year = this.user.customProcenaSchema.year;
                this.location = this.user.customProcenaSchema.location;
                this.position = this.user.customProcenaSchema.position;
                this.finished = false;
                this.user.customProcenaSchema.data.forEach(ele => {
                    if (ele.name == procena) {
                        this.finished = ele.finished;
                        for (let i = 0; i < this.activeProcena.questions.length; i++) {
                            if (this.activeProcena.questions[i].isSingleCheck) {
                                this.activeProcena.questions[i].isChecked = ele.answers[i]

                            } else if (this.activeProcena.questions[i].isComboAnswer) {
                                this.activeProcena.questions[i].isCombo.isChecked = ele.answers[i].answers
                                this.activeProcena.questions[i].isCombo.text = ele.answers[i].text

                            } else if (this.activeProcena.questions[i].isTextAnswer) {
                                this.activeProcena.questions[i].textAnswer = ele.answers[i]
                            }
                        }
                    }
                })
            }
        },
        makePdf() {
            window.html2canvas = html2canvas;
            var doc = new jsPDF("l", "pt", "a4");
            doc.html(document.querySelector("#pdf"), {
                callback: function (pdf) {
                    pdf.save("mypdf.pdf");
                }
            });
        },
        singleCheck(item, index) {
            for (let i = 0; i < item.length; i++) {
                if (index == i) {
                    item[i].checked = true;
                } else {
                    item[i].checked = false;
                }
            }
        },
        getTotal() {
            this.total = null;
            this.totalPercent = null;
            let hasNumber = null;
            this.activeProcena.questions.forEach((item) => {
                if (item.isComboAnswer) {
                    hasNumber += 1;
                    for (let i = 0; i < item.isCombo.isChecked.length; i++) {
                        if (item.isCombo.isChecked[i].checked) {
                            this.total += i + 1;
                        }
                    }
                } else if (item.isSingleCheck){
                    hasNumber += 1;
                    for (let i = 0; i < item.isChecked.length; i++) {
                        if (item.isChecked[i].checked) {
                            this.total += i + 1;
                        }
                    }
                }

            })
            this.totalPercent = parseFloat((hasNumber / this.total) * 100).toFixed(2)
        }
    },
    async created() {
    }
}

</script>
<template>
    <label v-if="customProcena.length > 0" class="pt-2 fw-bold p-0 m-0 text-nowrap">Postojeće procene:
        <select @change="generateProcena(selectedProcena)" v-model="selectedProcena">
            <option v-for="(item, i) in customProcena" :key="i">{{ item.name }}
            </option>
        </select>
    </label>
    <p v-if="customProcena.length == 0">Ne postoje snimljene procene za izabranu kompaniju!</p>
    <h1>Procena Kandidata</h1>
    <div class="d-flex flex-column">
        <label>Godište:</label>
        <input v-model="year">
        <label>Prebivalište:</label>
        <input v-model="location">
        <label>Pozicija:</label>
        <input v-model="position">
    </div>
    <div v-if="activeProcena" class="form-group">
        <div v-for="(item, i) in activeProcena.questions" :key="i">
            <!-- COMBO -->
            <div class="pt-1" v-if="item.isComboAnswer">
                <h3> {{ item.questionText }} </h3>
                <div v-for="(answ, i) in item.isCombo.isChecked" :key="i" class="form-check">
                    <label class="form-check-label">
                        {{ answ.answer }} &nbsp;&nbsp;
                    </label>
                    <input :checked="answ.checked" @click="singleCheck(item.isCombo.isChecked, i)" v-model="answ.checked"
                        class="form-check-input" type="radio" />
                </div>
                <textarea class="form-control" rows="3" v-model="item.isCombo.text"></textarea>
            </div>
            <!-- SINGLE CHECK -->
            <div class="pt-1" v-if="item.isSingleCheck">
                <h3>{{ item.questionText }}</h3>
                <div v-for="(answ, i) in item.isChecked" :key="i" class="form-check">
                    <label class="form-check-label">
                        {{ answ.answer }} &nbsp;&nbsp;
                    </label>
                    <input :checked="answ.checked" @click="singleCheck(item.isChecked, i)" v-model="answ.checked"
                        class="form-check-input" type="radio" />
                </div>
            </div>
            <!-- TEXT AREA -->
            <div class="pt-1" v-if="item.isTextAnswer">
                <h3>{{ item.questionText }}</h3><textarea class="form-control" rows="3"
                    v-model="item.textAnswer"></textarea>
            </div>
        </div>
        <button v-if="!finished" @click="submit" class="btn btn-primary mt-2">Snimi</button>
        <button v-if="finished" @click="showPdf" class="btn btn-primary mt-2">Prikaži PDF</button>
        <button v-if="finished" @click="makePdf" class="btn btn-primary mt-2 ms-2">Napravi PDF</button>


        <!-- SHOW PDF -->
        <div v-if="showHtmlPdf" id="pdf">
            <div id="feedBackHeader">
                <div class="centered">
                    <h3><b>Procena Kandidata</b></h3>
                </div>

                <div class="wrap">
                    <p>Legenda: 1 - Osnovno, 2 - Srednje, 3 - Odlicno</p>
                    <div class="container d-flex justify-content-center">
                        <div class="">
                            <p><b>Ime kandidata:</b> {{ user.name }}</p>
                            <p><b>Godiste:</b> {{ year }}</p>
                            <p><b>Prebivaliste:</b> {{ location }}</p>
                            <p><b>Pozicija za koju konkuriše:</b> {{ position }}</p>
                        </div>
                    </div>
                </div>

                <div class="questionAlt pt-2">
                    <h3>Ukupna Ocena: {{ total }} ({{ totalPercent }}%)</h3>
                </div>

            </div>
            <div v-for="(item, i) in activeProcena.questions" :key="i" class="question">
                <!-- COMBO -->
                <div class="pt-1" v-if="item.isComboAnswer">
                    <h3> {{ item.questionText }} </h3>
                    <div v-for="(answ, i) in item.isCombo.isChecked" :key="i">
                        <p v-if="answ.checked" class="p-0 m-0 text-start"><b>Ocena - </b>{{ i + 1 }}</p>
                    </div>
                    <p style="text-align: left;">
                        <span style="white-space: pre-line">
                            <b>Komentar: </b>{{ item.isCombo.text }}
                        </span>
                    </p>
                </div>
                <!-- SINGLE CHECK -->
                <div class="pt-1" v-if="item.isSingleCheck">
                    <h3>{{ item.questionText }}</h3>
                    <div v-for="(answ, i) in item.isChecked" :key="i">
                        <p v-if="answ.checked" class="p-0 m-0 text-start"><b>Ocena - </b>{{ i + 1 }}</p>
                    </div>
                </div>
                <!-- TEXT AREA -->
                <div class="pt-1" v-if="item.isTextAnswer">
                    <h3>{{ item.questionText }}</h3>
                    <p style="text-align: left;">
                        <span style="white-space: pre-line">
                            <b>Komentar: </b>{{ item.textAnswer }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.savedSuccessfully" class="bg-success p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">
        Promene uspešno snimljene!</div>
    <div v-if="this.saveFailed" class="bg-danger p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">{{
        this.saveFailedText }} Snimanje
        nije uspelo!</div>
</template>
<style scoped>
.form-check {
    display: inline-block
}

.bb {
    background-color: #ffaaaa;
}

.cc {
    background-color: #aaffaa;
}

.question:nth-child(even) {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8CFF90;
    ;
    padding-left: 20px;

    height: 250px;
    width: 810px;
    margin-left: 15px;
}

.question:nth-child(odd) {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8C90FF;
    padding-left: 20px;

    height: 250px;
    width: 810px;
    margin-left: 15px;
    margin-bottom: 60px;
}

#feedBackHeader {
    background-color: #ccccff;
    border-style: solid;
    border-color: black;
    border-width: medium;
    margin: 15px;
    padding: 15px;
    width: 810px;
    height: 570px;
    text-align: center;
    margin-bottom: 60px;
}

.wrap {
    border-color: black;
    border-style: solid;
    border-width: thin;
    padding: 15px;

    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: thin;
    padding: 15px;
}

.centered {
    text-align: center;
}

.container {
    align-content: space-evenly;
}

.questionAlt {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8C90FF;
    padding-left: 20px;

    height: 250px;
}
</style>