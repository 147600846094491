<template lang="html">
  <div class="podaci">
    <h1>Ime: {{ user.name }}</h1>
    <h4>email: {{ user.email }}</h4>
  </div>

  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          data-bs-parent="#accordionExample"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Performance Evaluation
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div
            style="font-family: Merriweather-Regular"
            id="kpi"
            :class="letterSpacing ? 'letterSpacing' : ''"
          >
            <div class="" v-if="evalEmpty">
              <h2>Ne postoji ni jedan Performance Evaluation</h2>
              <button
                class="btn btn-success mt-2"
                @click="addPerformanceEvaluationForSelectedUser()"
              >
                Dodaj performance evaluation za korisnika {{ user.name }}
              </button>
            </div>

            <div class="" v-if="!evalEmpty">
              <!-- <p>Datum izrade konacne procene: {{procenaDate}}</p> -->

              <div class="form-group">
                <label for="date">Datum Procene:</label>
                <select
                  name="date"
                  id="collegue"
                  v-model="CurrentDate"
                  @change="onChangeDate($event)"
                >
                  <option v-for="d in date" v-bind:key="d">{{ d }}</option>
                </select>
              </div>

              <div class="kompetence" v-if="kompetenceProc != 0">
                <h3>Kompetence ({{ kompetenceProc }}%)</h3>
                <div class="kompetence-wrapper">
                  <table id="kompetenceTable">
                    <tr>
                      <th>Kompetence :</th>
                      <th>Ponder</th>
                      <th>Samoprocena</th>
                      <th>Konacna Procena</th>
                      <th>Rangiranje</th>
                    </tr>

                    <tr v-for="(komp, i) in kompetence" v-bind:key="i">
                      <td>
                        <b>{{ komp.name }}</b>
                        <button
                          class="definicija"
                          type="button"
                          name="button"
                          @click="swapBoolKompetence(i)"
                        >
                          Definicija
                        </button>
                        <p
                          v-if="kompetenceBool[i].bool"
                          style="text-align: left;"
                        >
                          <span style="white-space: pre-line">
                            {{ kompetenceBool[i].comment }}
                          </span>
                        </p>
                      </td>
                      <td>{{ komp.ponder }}%</td>
                      <td v-if="komp.samoProcena == 1">
                        Ne Zadovoljava Očekivanja
                      </td>
                      <td v-if="komp.samoProcena == 2">Potrebno Poboljšanje</td>
                      <td v-if="komp.samoProcena == 3">
                        Zadovoljava Očekivanja
                      </td>
                      <td v-if="komp.samoProcena == 4">Iznad Očekivanja</td>
                      <td v-if="komp.konacnaProcena == 1">
                        Ne Zadovoljava Očekivanja
                      </td>
                      <td v-if="komp.konacnaProcena == 2">
                        Potrebno Poboljšanje
                      </td>
                      <td v-if="komp.konacnaProcena == 3">
                        Zadovoljava Očekivanja
                      </td>
                      <td v-if="komp.konacnaProcena == 4">Iznad Očekivanja</td>
                      <td v-if="komp.rangiranje != null">
                        {{ ((komp.rangiranje * 10000 + 1) / 10000).toFixed(2) }}
                      </td>
                      <td v-if="komp.rangiranje == null">0</td>
                    </tr>

                    <tr>
                      <td colspan="4">Ukupno ocena:</td>
                      <td>
                        {{ kompetenceOcena.toFixed(2) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="licni" v-if="licniProc != 0">
                <h3>{{ licniKpiTitle }} ({{ licniProc }}%)</h3>
                <table id="licniTable">
                  <tr>
                    <th>Kompetence :</th>
                    <th>Ponder</th>
                    <th>Samoprocena</th>
                    <th>Konacna Procena</th>
                    <th>Rangiranje</th>
                  </tr>

                  <tr v-for="(licni, j) in licniKpi" v-bind:key="j">
                    <td>
                      <b>{{ licni.name }}</b>
                      <button
                        class="definicija"
                        type="button"
                        name="button"
                        @Click="swapBoolLicni(j)"
                      >
                        Definicija
                      </button>
                      <p v-if="licniBool[j].bool" style="text-align: left;">
                        <span style="white-space: pre-line">
                          {{ licniBool[j].comment }}
                        </span>
                      </p>
                    </td>
                    <td>{{ licni.ponder }}%</td>
                    <td v-if="licni.samoProcena == 1">
                      Ne Zadovoljava Očekivanja
                    </td>
                    <td v-if="licni.samoProcena == 2">Potrebno Poboljšanje</td>
                    <td v-if="licni.samoProcena == 3">
                      Zadovoljava Očekivanja
                    </td>
                    <td v-if="licni.samoProcena == 4">Iznad Očekivanja</td>
                    <td v-if="licni.konacnaProcena == 1">
                      Ne Zadovoljava Očekivanja
                    </td>
                    <td v-if="licni.konacnaProcena == 2">
                      Potrebno Poboljšanje
                    </td>
                    <td v-if="licni.konacnaProcena == 3">
                      Zadovoljava Očekivanja
                    </td>
                    <td v-if="licni.konacnaProcena == 4">Iznad Očekivanja</td>
                    <td v-if="licni.rangiranje != null">
                      {{ ((licni.rangiranje * 10000 + 1) / 10000).toFixed(2) }}
                    </td>

                    <td v-if="licni.rangiranje == null">0</td>
                  </tr>

                  <tr>
                    <td colspan="4">Ukupno ocena test:</td>
                    <td>{{ licniOcena.toFixed(2) }}</td>
                    <!--  <td>{{ ((licniOcena * 10000 + 1) / 10000).toFixed(2) }}</td> -->
                  </tr>
                </table>
              </div>

              <div class="kompanijski" v-if="kompanijskiProc != 0">
                <h3>Kompanijski KPI ({{ kompanijskiProc }}%)</h3>
                <table id="kompanijskiTable">
                  <tr>
                    <th>Kompetence:</th>
                    <th>Ponder</th>
                    <th>Samoprocena</th>
                    <th>Konacna Procena</th>
                    <th>Rangiranje</th>
                  </tr>

                  <tr v-for="(kompa, k) in kompanijskiKpi" v-bind:key="k">
                    <td>
                      <b>{{ kompa.name }}</b>
                      <button
                        class="definicija"
                        type="button"
                        name="button"
                        @Click="swapBoolKompanijski(k)"
                      >
                        Definicija
                      </button>

                      <p
                        v-if="kompanijskiBool[k].bool"
                        style="text-align: left;"
                      >
                        <span style="white-space: pre-line">
                          {{ kompanijskiBool[k].comment }}
                        </span>
                      </p>
                    </td>

                    <td>{{ kompa.ponder }}%</td>
                    <td v-if="kompa.samoProcena == 1">
                      Ne Zadovoljava Očekivanja
                    </td>
                    <td v-if="kompa.samoProcena == 2">Potrebno Poboljšanje</td>
                    <td v-if="kompa.samoProcena == 3">
                      Zadovoljava Očekivanja
                    </td>
                    <td v-if="kompa.samoProcena == 4">Iznad Očekivanja</td>
                    <td v-if="kompa.konacnaProcena == 1">
                      Ne Zadovoljava Očekivanja
                    </td>
                    <td v-if="kompa.konacnaProcena == 2">
                      Potrebno Poboljšanje
                    </td>
                    <td v-if="kompa.konacnaProcena == 3">
                      Zadovoljava Očekivanja
                    </td>
                    <td v-if="kompa.konacnaProcena == 4">Iznad Očekivanja</td>
                    <td v-if="kompa.rangiranje != null">
                      {{ ((kompa.rangiranje * 10000 + 1) / 10000).toFixed(2) }}
                    </td>
                    <td v-if="kompa.rangiranje == null">0</td>
                  </tr>

                  <tr>
                    <td colspan="4">Ukupno ocena:</td>
                    <td>
                      {{ kompanijskiOcena.toFixed(2) }}
                    </td>
                  </tr>
                </table>
              </div>

              <h4>Komentar Samoprocena:</h4>
              <div id="komentarEval">
                <p style="text-align: left;">
                  <span style="white-space: pre-line">
                    {{ samoProcena }}
                  </span>
                </p>
              </div>

              <h4>Komentar Konacna procena:</h4>
              <div id="komentarEval">
                <p style="text-align: left;">
                  <span style="white-space: pre-line">
                    {{ konacnaProcena }}
                  </span>
                </p>
              </div>
              <h2 id="jedan" v-if="ukupnoKpi < 0.749">
                Ukupan učinak:
                {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
              </h2>
              <h2 id="dva" v-if="ukupnoKpi >= 0.749 && ukupnoKpi < 0.995">
                Ukupan učinak:
                {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
              </h2>
              <h2 id="tri" v-if="ukupnoKpi >= 0.995 && ukupnoKpi < 1.175">
                Ukupan učinak:
                {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
              </h2>
              <h2 id="cetiri" v-if="ukupnoKpi >= 1.175">
                Ukupan učinak:
                {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
              </h2>
            </div>
          </div>

          <!-- <div class="evaluation1" v-if="((randomNumber/100).toFixed(2)<0.75)">
              <h4>Rezultat: {{(randomNumber/100).toFixed(2)}}</h4>
              <p>Rezultati loši, potrebno unapređenje za vršenje trenutne uloge ili prelazak na drugo radno mesto	</p>
          </div>

          <div class="evaluation2" v-if="((randomNumber/100).toFixed(2)>=0.75 && (randomNumber/100).toFixed(2)<=0.99 )">
              <h4>Rezultat: {{(randomNumber/100).toFixed(2)}}</h4>
              <p>Rezultati dobri, ali  neophodan razvoj za vršenje trenutne uloge</p>
          </div>

          <div class="evaluation3" v-if="((randomNumber/100).toFixed(2)>0.99 && (randomNumber/100).toFixed(2)<=1.17 )">
              <h4>Rezultat: {{(randomNumber/100).toFixed(2)}}</h4>
              <p>Apsolutno ostvarenje rezultata u trenutnoj ulozi</p>
          </div>

          <div class="evaluation3" v-if="((randomNumber/100).toFixed(2)>1.17)">
              <h4>Rezultat: {{(randomNumber/100).toFixed(2)}}</h4>
              <p>Prebačaj rezultata u trenutnoj ulozi</p>
          </div> -->
        </div>
        <div class="mx-3 mb-3 d-flex flex-inline">
          <div class="w-50 d-flex justify-content-center pe-1">
            <button class="btn btn-primary w-100" @click="makePDFKPI">
              MakePDF
            </button>
          </div>
          <div class="w-50 d-flex justify-content-center ps-1">
            <button class="btn btn-success w-100" onclick="window.print()">
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          data-bs-parent="#accordionExample"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          The Vitality Quotient
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div id="vqTest">
            <h4>VQ Test</h4>
            <p v-if="!user.vqTest.finished">Nije uradjen</p>
            <div class="" v-if="user.vqTest.finished">
              <p>Radjen: {{ vqDate }}</p>
              <div class="vqPovucen" v-if="this.user.vqTest.result <= -15">
                <h3>
                  "VQ" prоfil је: "Pоvučеn". ({{ this.user.vqTest.result }})
                </h3>
                <p>
                  Vаšа "vоlја zа živоtоm" је slаbа ili pоtisnutа. Vi nistе
                  zаdоvоlјni svеtоm ili živоtоm tаkvim kаkvi jesu, аli nistе
                  sklоni dа ih menjate. Gеnеrаlnо sе оsеćаtе ugrоžеnim,
                  dоgаđајimа i lјudimа. Višе vоlitе dа sе pоvučеtе nеgо dа se
                  eksponirate. Žеlitе dа vоditе mirаn živоt, dаlеkо оd svеtskоg
                  mеtеžа. Nеmаtе ni јаkе žеlје, ni јаkе аmbiciје. Drugi Vаs mоgu
                  dоživеti kао pаsivnе ili stаtičnе. Bеz iniciјаtivе,
                  nеprеduzimlјivе. Kао nеkоg kо višе vоli kоntеmplаciјu оd
                  аkciје. Vеrоvаtnо је Vаš mоtо: "Nеmа pоtrеbе gurаti rеku, оnа
                  svаkаkо tеčе."
                </p>
              </div>
              <div
                class="vqOprezan"
                v-if="
                  this.user.vqTest.result > -15 && this.user.vqTest.result <= -1
                "
              >
                <h3>
                  "VQ" Profil je: "Oprezan." ({{ this.user.vqTest.result }})
                </h3>
                <p>
                  Vasa "volja za zivotom" je u pripravnosti. Vi ste budni,
                  posmatrate svet oko sebe i procenjujete potencijalni uticaj
                  dogadjaja na Vas zivot. Spremni ste da reagujete ako je
                  potrebno, ali niste skloni da se pokrenete i preuzmete
                  inicijativu, ukoliko niste primorani na to.
                </p>
                <p>
                  Pоkušаćete dа izbеgnе rizikе i dа sе zаštititе оd
                  nеоčеkivаnоg. Pоuzdаni ste kао prоfеsiоnаlаc i kао priјаtеlј,
                  svе dоk sе nе nаđеtе u kоntrоvеrznој ili rizičnој situаciјi.
                  Prihvаtićеtе оpšti trеnd rаzmišlјаnjа zа kојi prоcеnitе dа је
                  rаzumаn. U principu vоlitе lјudе, аli žеlitе dа prоvеritе dа
                  li su pоuzdаni. Kаdа sе sа nеkim priјаtnо оsеćаtе, dаćеtе svе
                  оd sеbе dа mu budеtе оd pоmоći. Drugi Vаs dоživlјаvајu prе kао
                  nеkоg kо prihvаtа inciјаtivu, nеgо kао iniciјаtоrа. Kао nеkоg
                  kо ćе sеdеti nа оgrаdi dоk nе vidi u kоm prаvcu vеtаr duvа.
                  Kао stеrеоtipnоg pо nаčinu rаzmišlјаnjа i kао nе bаš
                  stimulаtivnоg. Vаš mоtо је vеrоvаtnо: "Višе vоlim dа igrаm nа
                  sigurnо".
                </p>
              </div>
              <div
                class="vqAngazovan"
                v-if="
                  this.user.vqTest.result >= 1 && this.user.vqTest.result <= 14
                "
              >
                <h3>
                  "VQ" Profil je:"Angažovan". ({{ this.user.vqTest.result }})
                </h3>
                <p>
                  Vаšа "vоlја zа živоtоm" је аktivirаnа. Vi stе "аktеr". Pоkrеćе
                  Vаs svеt. Živоt је pun mоgućnоsti kоје su i zаbаvnе i kоrisnе.
                  Žеlitе dа idеtе dаlје: dа rаditе stvаri i dа uživаtе u živоtu.
                  Vi ćеtе inicirаti nоvе prојеktе, kаkо u Vаšеm prоfеsiоnаlnоm,
                  tаkо i u Vаšеm ličnоm živоtu. Privućićе Vаs lјudi sа kојimа
                  оsеćаte dа mоžеtе dа rаditе rаznе stvаri: pаrtnеri, prе nеgо
                  drugоvi ili srоdnе dušе. Vоlitе prеuzimаnjе оdgоvоrnоsti i nе
                  plаšitе sе оd pојаvlјivаnjа u јаvnоsti i prihvatanja rizikа.
                  Маštоviti stе.
                </p>
                <p>
                  Drugi lјudi ćе Vаs vеrоvаtnо dоživеti kао lidеrа, kао nеkоg sа
                  idејаmа i iniciјаtivоm, аktivnоg i pоuzdаnоg, zаnimlјivоg.
                  Моždа ćе Vаs dоživеti i kао prеvišе zаuzеtоg, kао nеkоg kо sе
                  nе prеdаје zаbаvi ili оpuštаnju, kао nеkоg kоgа sе nе tiču
                  ličnа оsеćаnjа ili prоblеmi drugih lјudi, kао еgоistu.
                </p>
                <p>Vаš mоtо је vеrоvаtnо:"Hајdе dа tо urаdimо!"</p>
              </div>
              <div class="vqHiperaktivan" v-if="this.user.vqTest.result >= 15">
                <h3>
                  "VQ" prоfil је: "Hipеr-аktivаn". ({{
                    this.user.vqTest.result
                  }})
                </h3>
                <p>
                  Vаšа "vоlја zа živоtоm" је оgrоmnа. Vi stе "prеduzеtnik",
                  "оsvајаč". U sebi kоnstаntnо osećate pritisаk. Vi sе nikаdа nе
                  mоžеtе stvаrnо оpustiti i čаk i kаdа uživаtе u zаbаvi, uvеk i
                  dаlје rаzmišlјаtе о nеkоm pоslu kојi trеbа dа оbаvitе. Моrаtе
                  biti аngаžоvаni u nеkоm prојеktu i morate imati osećaj dа
                  stаlnо širitе svојu "mоć zа živоt". Pо Vаmа, "svеt trеbа
                  zgrаbiti!" Vi stе nеstrplјivi, nikаd zаdоvоlјni, оsim tоkоm
                  krаtkih trеnutаkа pоstignućа. Pоtrеbnо Vаm је dа vоditе, dа
                  prеuzimаtе оdgоvоrnоst, јеr u suprоtnоm mislitе dа sе stvаri
                  nеćе оdviјаti brzо ili dоvоlјnо еfikаsnо, оdnоsnо nеćе ići "nа
                  Vаš nаčin". Drugi lјudi su u suštini izvršiоci Vаših
                  pоduhvаtа, oni trеbа dа iznеsu Vаšе prојеktе. Nistе uоpštе
                  zаintеrеsоvаni zа njihоv lični živоt. Vеоmа stе krеаtivni. Оd
                  Vаs mоžе pоstаti vеliki lidеr, pоd uslоvоm dа nе prеtеruјеtе
                  ...
                </p>
                <p>
                  Drugi Vаs uglаvnоm vidе kао hipеr-аktivаnоg, vrlо mаštоvitоg,
                  uvеk u pоkrеtu, inоvаtivnоg. Sа mаnjе pоzitivnе strаnе, mоgli
                  bi Vаs smаtrаti zа prеvišе nаpаdnоg, аrоgаntnоg, аgrеsivnоg,
                  nаpоrnоg, zаpоvеdnički nаstrојеnоg. Kао nikаd zаdоvоlјnоg
                  rаdоhоličаrа, kојi nе shvаtа u čеmu su prаvе vrеdnоsti živоtа.
                  Kао nеоsеtlјivоg prеmа drugim lјudimа i bеz ličnоg živоtа.
                </p>
                <p>Vаš mоtо је vеrоvаtnо: "Višе је bоlје".</p>
              </div>
            </div>
            <!-- <img src="@/assets/goldWApp.svg" alt=""> -->
          </div>
          <button @click="makePDFVQ">MakePDF</button>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          data-bs-parent="#accordionExample"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          Test Licnosti
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <p v-if="!user.testLicnosti.finished">Nije uradjen</p>
          <div class="" v-if="user.testLicnosti.finished">
            <p>Radjen: {{ testLicnostiDate }}</p>
            <line-chart
              :data="chartData"
              :library="chartOptions"
              id="chart"
              width="800px"
              height="300px"
              v-if="!isMobile()"
              :download="true"
            >
            </line-chart>
            <line-chart
              :data="chartDataDetails"
              :library="chartOptionsDetailed"
              id="detailedChart"
              :download="true"
              width="800px"
              height="300px"
              v-if="!isMobile()"
            >
            </line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThreeProcena"
          data-bs-parent="#accordionExample"
          aria-expanded="false"
          aria-controls="collapseThreeProcena"
        >
          Procena Kandidata
        </button>
      </h2>
      <div
        id="collapseThreeProcena"
        class="accordion-collapse collapse"
        aria-labelledby="headingThreeProcena"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <ProcenaKandidata :user="user" :noPdf="false"> </ProcenaKandidata>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThreeExtra">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThreeCustomProcena"
          data-bs-parent="#accordionExample"
          aria-expanded="false"
          aria-controls="collapseThreeCustomProcena"
        >
          Generisana Procena Kandidata
        </button>
      </h2>
      <div
        id="collapseThreeCustomProcena"
        class="accordion-collapse collapse"
        aria-labelledby="headingThreeCustomProcena"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <CustomProcena
            :customProcena="selectedCompanyCustomProcena"
            :user="user"
            :noPdf="false"
          >
          </CustomProcena>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFour">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
          data-bs-parent="#accordionExample"
          aria-expanded="false"
          aria-controls="collapseFour"
        >
          Belbinov Test
        </button>
      </h2>
      <div
        id="collapseFour"
        class="accordion-collapse collapse"
        aria-labelledby="headingFour"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div id="belvin">
            <table id="belvinTable">
              <tr>
                <th>Uloge</th>
                <th>Rezultati</th>
              </tr>
              <tr>
                <td>Snalazljivi Organizator</td>
                <td v-if="user.belbinTest.SO <= 6">
                  Nisko ({{ user.belbinTest.SO }})
                </td>
                <td v-if="user.belbinTest.SO > 6 && user.belbinTest.SO <= 11">
                  Prosecno ({{ user.belbinTest.SO }})
                </td>
                <td v-if="user.belbinTest.SO > 11 && user.belbinTest.SO <= 16">
                  Visoko ({{ user.belbinTest.SO }})
                </td>
                <td v-if="user.belbinTest.SO >= 17">
                  Veoma Visoko ({{ user.belbinTest.SO }})
                </td>
              </tr>
              <tr>
                <td>Rodjeni vodja</td>
                <td v-if="user.belbinTest.RV <= 6">
                  Nisko ({{ user.belbinTest.RV }})
                </td>
                <td v-if="user.belbinTest.RV > 6 && user.belbinTest.RV <= 10">
                  Prosecno ({{ user.belbinTest.RV }})
                </td>
                <td v-if="user.belbinTest.RV > 10 && user.belbinTest.RV <= 13">
                  Visoko ({{ user.belbinTest.RV }})
                </td>
                <td v-if="user.belbinTest.RV >= 14">
                  Veoma Visoko ({{ user.belbinTest.RV }})
                </td>
              </tr>
              <tr>
                <td>Osoba od Akcije</td>
                <td v-if="user.belbinTest.OA <= 8">
                  Nisko ({{ user.belbinTest.OA }})
                </td>
                <td v-if="user.belbinTest.OA > 8 && user.belbinTest.OA <= 13">
                  Prosecno ({{ user.belbinTest.OA }})
                </td>
                <td v-if="user.belbinTest.OA > 13 && user.belbinTest.OA <= 17">
                  Visoko ({{ user.belbinTest.OA }})
                </td>
                <td v-if="user.belbinTest.OA >= 18">
                  Veoma Visoko ({{ user.belbinTest.OA }})
                </td>
              </tr>
              <tr>
                <td>Osoba sa Idejama</td>
                <td v-if="user.belbinTest.OI <= 4">
                  Nisko ({{ user.belbinTest.OI }})
                </td>
                <td v-if="user.belbinTest.OI > 4 && user.belbinTest.OI <= 8">
                  Prosecno ({{ user.belbinTest.OI }})
                </td>
                <td v-if="user.belbinTest.OI > 8 && user.belbinTest.OI <= 12">
                  Visoko ({{ user.belbinTest.OI }})
                </td>
                <td v-if="user.belbinTest.OI >= 13">
                  Veoma Visoko ({{ user.belbinTest.OI }})
                </td>
              </tr>
              <tr>
                <td>Osoba za kontakte</td>
                <td v-if="user.belbinTest.OK <= 6">
                  Nisko ({{ user.belbinTest.OK }})
                </td>
                <td v-if="user.belbinTest.OK > 6 && user.belbinTest.OK <= 9">
                  Prosecno ({{ user.belbinTest.OK }})
                </td>
                <td v-if="user.belbinTest.OK > 9 && user.belbinTest.OK <= 11">
                  Visoko ({{ user.belbinTest.OK }})
                </td>
                <td v-if="user.belbinTest.OK >= 12">
                  Veoma Visoko ({{ user.belbinTest.OK }})
                </td>
              </tr>
              <tr>
                <td>Sudija</td>
                <td v-if="user.belbinTest.S <= 5">
                  Nisko ({{ user.belbinTest.S }})
                </td>
                <td v-if="user.belbinTest.S > 5 && user.belbinTest.S <= 9">
                  Prosecno ({{ user.belbinTest.S }})
                </td>
                <td v-if="user.belbinTest.S > 9 && user.belbinTest.S <= 12">
                  Visoko ({{ user.belbinTest.S }})
                </td>
                <td v-if="user.belbinTest.S >= 13">
                  Veoma Visoko ({{ user.belbinTest.S }})
                </td>
              </tr>
              <tr>
                <td>Covek tima</td>
                <td v-if="user.belbinTest.CT <= 8">
                  Nisko ({{ user.belbinTest.CT }})
                </td>
                <td v-if="user.belbinTest.CT > 8 && user.belbinTest.CT <= 12">
                  Prosecno ({{ user.belbinTest.CT }})
                </td>
                <td v-if="user.belbinTest.CT > 12 && user.belbinTest.CT <= 16">
                  Visoko ({{ user.belbinTest.CT }})
                </td>
                <td v-if="user.belbinTest.CT >= 17">
                  Veoma Visoko ({{ user.belbinTest.CT }})
                </td>
              </tr>
              <tr>
                <td>Perfekcionista</td>
                <td v-if="user.belbinTest.P <= 3">
                  Nisko ({{ user.belbinTest.P }})
                </td>
                <td v-if="user.belbinTest.P > 3 && user.belbinTest.P <= 6">
                  Prosecno ({{ user.belbinTest.P }})
                </td>
                <td v-if="user.belbinTest.P > 6 && user.belbinTest.P <= 9">
                  Visoko ({{ user.belbinTest.P }})
                </td>
                <td v-if="user.belbinTest.P >= 10">
                  Veoma Visoko ({{ user.belbinTest.P }})
                </td>
              </tr>
            </table>

            <div v-if="belbinNajvisiPrvi == 0">
              <p>
                <b
                  >Primarna Uloga: Snalazljivi Organizator ({{
                    user.belbinTest.SO
                  }})</b
                >
                Koncepte i planove pretvara u prakticnu akciju. Planove
                ostvaruje sistematicno i efektno. Odlike: uravnotezen i
                disciplinovan. Zahvaljujuci njemu projekti i planovi se i
                prakticno primenjuju. Voli tacnost i doslednost, ne voli kada se
                planovi menjaju.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 1">
              <p>
                <b>Primarna Uloga: Rodjeni Vodja ({{ user.belbinTest.RV }})</b>
                Daje svoj pecat i kontrolise nacin na koji grupa pokusava da
                ostvari svoje ciljeve. Sposoban je da uspesno iskoristi
                kvalitete koje grupa poseduje. Zna koje su jake, a koje su slabe
                tacke clanova grupe i u stanju je da iskoristi prednosti i
                potencijale svakog pojedinca u grupi. Odlike: uravnotezen,
                dominantan, ekstrovertan. Vise se oslanja na zdravorazumsko
                rasudjivanje nego na intelektualno promisljanje. Nije agresivan
                dok upravlja grupom.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 2">
              <p>
                <b
                  >Primarna Uloga: Osoba Od Akcije ({{ user.belbinTest.OA }})</b
                >
                Oblikuje nacin na koji se koriste potencijali grupe. Drzi paznju
                usmerenu na odredjivanje ciljeva i prioriteta, vodi grupnu
                diskusiju i oblikuje rezultate grupnih aktivnosti. Odlike:
                neumoran, dominantan, ekstrovertan, impulsivan, lako se
                iznervira. Zeli brzo da dodje do rezultata. Poseduje takmicarski
                duh, arogantan je, ali zahvaljujuci njemu zadaci bivaju
                obavljeni.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 3">
              <p>
                <b
                  >Primarna Uloga: Osoba Sa Idejama ({{
                    user.belbinTest.OI
                  }})</b
                >
                Predlaze nove ideje i strategije, pojedinacno obradjuje
                najvaznije probleme, pokusava da nametne svoje sopstvene ideje,
                koristeci grupni pristup problemu (na bazi konfrontacije).
                Odlike: dominantan, inteligentan, introvertan. Moze se desiti da
                se izgubi u detaljima. Takodje kritikuje tudje ideje. Sto je
                problem veci, to je za njega veci i izazov da ga resi. Smatra da
                ideje mogu zvucati cudno ili nemoguce (u pocetku). Oko sebe
                stvara auru genija.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 4">
              <p>
                <b
                  >Primarna Uloga: Osoba Za Kontakte ({{
                    user.belbinTest.OK
                  }})</b
                >
                Istrazuje, analizira i sabira informacije o idejama,
                dostignucima i delatnostima van grupe. Ostvaruje veze sa
                ljudima, koje mogu biti korisne za grupu, sposoban je da vodi
                pregovore. Odlike: uravnotezen, dominantan, ekstrovertan.
                Podstice inovacije, dobar improvizator. Pomalo suzdrzan kada
                trazi dobrobit za grupu. Cesto kaze: „Nove mogucnosti bice
                stvorene na tudjim greskama“.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 5">
              <p>
                <b>Primarna Uloga: Sudija ({{ user.belbinTest.S }})</b>
                Analizira problem, procenjuje ideje i predloge. Zahvaljujuci
                njemu grupa ne prenagljuje i donosi dobro promisljene odluke.
                Odlike: inteligentan, uravnotezen, introvertan. Najobjektivniji
                je, celovita licnost, ne unosi emocije, voli da ima vremena za
                razmisljanje. Ponekad mu manjka entuzijazma, ali njegova
                smirenost doprinosi da grupa donosi odmerene odluke.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 6">
              <p>
                <b>Primarna Uloga: Covek Tima ({{ user.belbinTest.CT }})</b>
                Podrzava clanove grupe, u slucaju greske uvek odrzava visok
                moral grupe, sposoban je da izbegne konflikt, stvara timski duh,
                osnazuje saradnju i komunikaciju u grupi. Odan je grupi. Odlike:
                ekstrovertan, uravnotezen, nema veliku potrebu za dominacijom i
                rivalstvom, saosecajan. Njegov doprinos mozda nije bas sasvim
                jasan, ali njegova odanost i posvecenost timu su na velikoj
                ceni. Ne voli da se konfrotira.
              </p>
            </div>
            <div v-if="belbinNajvisiPrvi == 7">
              <p>
                <b>Primarna Uloga: Perfekcionista ({{ user.belbinTest.P }})</b>
                Usmeren je na specificne akcije: privodjenje zadataka kraju u
                okviru odredjenog roka i obezbedjivanje razultata najviseg
                kvaliteta. Moze biti tezak u kontaktima, zbog toga sto se gubi u
                detaljima koji nisu toliko vazni za izvodjenje zadatka. Uvek je
                svestan ciljeva. Odlike: tezak, napet, introvertan,
                disciplinovan.
              </p>
            </div>

            <div v-if="belbinNajvisiDrugi == 0">
              <p>
                <b
                  >Sekundarna Uloga: Snalazljivi Organizator ({{
                    user.belbinTest.SO
                  }})</b
                >
                Koncepte i planove pretvara u prakticnu akciju. Planove
                ostvaruje sistematicno i efektno. Odlike: uravnotezen i
                disciplinovan. Zahvaljujuci njemu projekti i planovi se i
                prakticno primenjuju. Voli tacnost i doslednost, ne voli kada se
                planovi menjaju.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 1">
              <p>
                <b>Sekundarna Uloga: Rodjeni Vođa ({{ user.belbinTest.RV }})</b>
                Daje svoj pecat i kontrolise nacin na koji grupa pokusava da
                ostvari svoje ciljeve. Sposoban je da uspesno iskoristi
                kvalitete koje grupa poseduje. Zna koje su jake, a koje su slabe
                tacke clanova grupe i u stanju je da iskoristi prednosti i
                potencijale svakog pojedinca u grupi. Odlike: uravnotezen,
                dominantan, ekstrovertan. Vise se oslanja na zdravorazumsko
                rasudjivanje nego na intelektualno promisljanje. Nije agresivan
                dok upravlja grupom.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 2">
              <p>
                <b
                  >Sekundarna Uloga: Osoba Od Akcije ({{
                    user.belbinTest.OA
                  }})</b
                >
                Oblikuje nacin na koji se koriste potencijali grupe. Drzi paznju
                usmerenu na odredjivanje ciljeva i prioriteta, vodi grupnu
                diskusiju i oblikuje rezultate grupnih aktivnosti. Odlike:
                neumoran, dominantan, ekstrovertan, impulsivan, lako se
                iznervira. Zeli brzo da dodje do rezultata. Poseduje takmicarski
                duh, arogantan je, ali zahvaljujuci njemu zadaci bivaju
                obavljeni.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 3">
              <p>
                <b
                  >Sekundarna Uloga: Osoba Sa Idejama ({{
                    user.belbinTest.OI
                  }})</b
                >
                Predlaze nove ideje i strategije, pojedinacno obradjuje
                najvaznije probleme, pokusava da nametne svoje sopstvene ideje,
                koristeci grupni pristup problemu (na bazi konfrontacije).
                Odlike: dominantan, inteligentan, introvertan. Moze se desiti da
                se izgubi u detaljima. Takodje kritikuje tudje ideje. Sto je
                problem veci, to je za njega veci i izazov da ga resi. Smatra da
                ideje mogu zvucati cudno ili nemoguce (u pocetku). Oko sebe
                stvara auru genija.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 4">
              <p>
                <b
                  >Sekundarna Uloga: Osoba Za Kontakte ({{
                    user.belbinTest.OK
                  }})</b
                >
                Istrazuje, analizira i sabira informacije o idejama,
                dostignucima i delatnostima van grupe. Ostvaruje veze sa
                ljudima, koje mogu biti korisne za grupu, sposoban je da vodi
                pregovore. Odlike: uravnotezen, dominantan, ekstrovertan.
                Podstice inovacije, dobar improvizator. Pomalo suzdrzan kada
                trazi dobrobit za grupu. Cesto kaze: „Nove mogucnosti bice
                stvorene na tudjim greskama“.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 5">
              <p>
                <b>Sekundarna Uloga: Sudija ({{ user.belbinTest.S }})</b>
                Analizira problem, procenjuje ideje i predloge. Zahvaljujuci
                njemu grupa ne prenagljuje i donosi dobro promisljene odluke.
                Odlike: inteligentan, uravnotezen, introvertan. Najobjektivniji
                je, celovita licnost, ne unosi emocije, voli da ima vremena za
                razmisljanje. Ponekad mu manjka entuzijazma, ali njegova
                smirenost doprinosi da grupa donosi odmerene odluke.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 6">
              <p>
                <b>Sekundarna Uloga: Covek Tima ({{ user.belbinTest.CT }})</b>
                Podrzava clanove grupe, u slucaju greske uvek odrzava visok
                moral grupe, sposoban je da izbegne konflikt, stvara timski duh,
                osnazuje saradnju i komunikaciju u grupi. Odan je grupi. Odlike:
                ekstrovertan, uravnotezen, nema veliku potrebu za dominacijom i
                rivalstvom, saosecajan. Njegov doprinos mozda nije bas sasvim
                jasan, ali njegova odanost i posvecenost timu su na velikoj
                ceni. Ne voli da se konfrotira.
              </p>
            </div>
            <div v-if="belbinNajvisiDrugi == 7">
              <p>
                <b
                  >Sekundarna Uloga: Perfekcionista ({{ user.belbinTest.P }})</b
                >
                Usmeren je na specificne akcije: privodjenje zadataka kraju u
                okviru odredjenog roka i obezbedjivanje razultata najviseg
                kvaliteta. Moze biti tezak u kontaktima, zbog toga sto se gubi u
                detaljima koji nisu toliko vazni za izvodjenje zadatka. Uvek je
                svestan ciljeva. Odlike: tezak, napet, introvertan,
                disciplinovan.
              </p>
            </div>

            <div v-if="belbinNajnizi == 0">
              <p>
                <b
                  >Potrebno Unapredjenje: Snalazljivi Organizator ({{
                    user.belbinTest.SO
                  }})</b
                >
                Koncepte i planove pretvara u prakticnu akciju. Planove
                ostvaruje sistematicno i efektno. Odlike: uravnotezen i
                disciplinovan. Zahvaljujuci njemu projekti i planovi se i
                prakticno primenjuju. Voli tacnost i doslednost, ne voli kada se
                planovi menjaju.
              </p>
            </div>
            <div v-if="belbinNajnizi == 1">
              <p>
                <b
                  >Potrebno Unapredjenje: Rođeni Vodja ({{
                    user.belbinTest.RV
                  }})</b
                >
                Daje svoj pecat i kontrolise nacin na koji grupa pokusava da
                ostvari svoje ciljeve. Sposoban je da uspesno iskoristi
                kvalitete koje grupa poseduje. Zna koje su jake, a koje su slabe
                tacke clanova grupe i u stanju je da iskoristi prednosti i
                potencijale svakog pojedinca u grupi. Odlike: uravnotezen,
                dominantan, ekstrovertan. Vise se oslanja na zdravorazumsko
                rasudjivanje nego na intelektualno promisljanje. Nije agresivan
                dok upravlja grupom.
              </p>
            </div>
            <div v-if="belbinNajnizi == 2">
              <p>
                <b
                  >Potrebno Unapredjenje: Osoba Od Akcije ({{
                    user.belbinTest.OA
                  }})</b
                >
                Oblikuje nacin na koji se koriste potencijali grupe. Drzi paznju
                usmerenu na odredjivanje ciljeva i prioriteta, vodi grupnu
                diskusiju i oblikuje rezultate grupnih aktivnosti. Odlike:
                neumoran, dominantan, ekstrovertan, impulsivan, lako se
                iznervira. Zeli brzo da dodje do rezultata. Poseduje takmicarski
                duh, arogantan je, ali zahvaljujuci njemu zadaci bivaju
                obavljeni.
              </p>
            </div>
            <div v-if="belbinNajnizi == 3">
              <p>
                <b
                  >Potrebno Unapredjenje: Osoba Sa Idejama ({{
                    user.belbinTest.OI
                  }})</b
                >
                Predlaze nove ideje i strategije, pojedinacno obradjuje
                najvaznije probleme, pokusava da nametne svoje sopstvene ideje,
                koristeci grupni pristup problemu (na bazi konfrontacije).
                Odlike: dominantan, inteligentan, introvertan. Moze se desiti da
                se izgubi u detaljima. Takodje kritikuje tudje ideje. Sto je
                problem veci, to je za njega veci i izazov da ga resi. Smatra da
                ideje mogu zvucati cudno ili nemoguce (u pocetku). Oko sebe
                stvara auru genija.
              </p>
            </div>
            <div v-if="belbinNajnizi == 4">
              <p>
                <b
                  >Potrebno Unapredjenje: Osoba Za Kontakte ({{
                    user.belbinTest.OK
                  }})</b
                >
                Istrazuje, analizira i sabira informacije o idejama,
                dostignucima i delatnostima van grupe. Ostvaruje veze sa
                ljudima, koje mogu biti korisne za grupu, sposoban je da vodi
                pregovore. Odlike: uravnotezen, dominantan, ekstrovertan.
                Podstice inovacije, dobar improvizator. Pomalo suzdrzan kada
                trazi dobrobit za grupu. Cesto kaze: „Nove mogucnosti bice
                stvorene na tudjim greskama“.
              </p>
            </div>
            <div v-if="belbinNajnizi == 5">
              <p>
                <b>Potrebno Unapredjenje: Sudija ({{ user.belbinTest.S }})</b>
                Analizira problem, procenjuje ideje i predloge. Zahvaljujuci
                njemu grupa ne prenagljuje i donosi dobro promisljene odluke.
                Odlike: inteligentan, uravnotezen, introvertan. Najobjektivniji
                je, celovita licnost, ne unosi emocije, voli da ima vremena za
                razmisljanje. Ponekad mu manjka entuzijazma, ali njegova
                smirenost doprinosi da grupa donosi odmerene odluke.
              </p>
            </div>
            <div v-if="belbinNajnizi == 6">
              <p>
                <b
                  >Potrebno Unapredjenje: Covek Tima ({{
                    user.belbinTest.CT
                  }})</b
                >Podrzava clanove grupe, u slucaju greske uvek odrzava visok
                moral grupe, sposoban je da izbegne konflikt, stvara timski duh,
                osnazuje saradnju i komunikaciju u grupi. Odan je grupi. Odlike:
                ekstrovertan, uravnotezen, nema veliku potrebu za dominacijom i
                rivalstvom, saosecajan. Njegov doprinos mozda nije bas sasvim
                jasan, ali njegova odanost i posvecenost timu su na velikoj
                ceni. Ne voli da se konfrotira.
              </p>
            </div>
            <div v-if="belbinNajnizi == 7">
              <p>
                <b
                  >Potrebno Unapredjenje: Perfekcionista ({{
                    user.belbinTest.P
                  }})</b
                >
                Usmeren je na specificne akcije: privodjenje zadataka kraju u
                okviru odredjenog roka i obezbedjivanje razultata najviseg
                kvaliteta. Moze biti tezak u kontaktima, zbog toga sto se gubi u
                detaljima koji nisu toliko vazni za izvodjenje zadatka. Uvek je
                svestan ciljeva. Odlike: tezak, napet, introvertan,
                disciplinovan.
              </p>
            </div>
          </div>

          <button @click="makePDFBelvin">makePDF</button>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            data-bs-parent="#accordionExample"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            360 Upitnik Za Procenu Liderskih i Menadžerskih Sposobnosti
          </button>
        </h2>
        <div
          id="collapseFive"
          class="accordion-collapse collapse"
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div id="feedbackPDF">
              <div id="feedBackHeader">
                <div class="feedbackHeadElement">
                  <h2>
                    360 Upitnik Za Procenu Liderskih i Menadžerskih Sposobnosti
                  </h2>
                  <h3>(Prosek ukupno)</h3>
                  <div class="marginTop80px">
                    <h4>
                      Podredjeni:
                      {{ this.user.feedBackTest.prosek.podredjeni.toFixed(2) }}
                    </h4>
                    <h4>
                      Nadredjeni:
                      {{ this.user.feedBackTest.prosek.nadredjeni.toFixed(2) }}
                    </h4>
                    <h4>
                      Isti Nivo:
                      {{ this.user.feedBackTest.prosek.istiNivo.toFixed(2) }}
                    </h4>
                    <h4>
                      Ukupno:
                      {{ this.user.feedBackTest.prosek.ukupno.toFixed(2) }}
                    </h4>
                  </div>
                  <!-- <table id="tableHead">
                      <tr>
                        <th>Podredjeni</th>
                        <th>Nadredjeni</th>
                        <th>Isti Nivo</th>
                        <th>Ukupno</th>
                      </tr>
                      <tr>
                         <td>{{this.user.feedBackTest.prosek.podredjeni.toFixed(2)}}</td>
                         <td>{{this.user.feedBackTest.prosek.nadredjeni.toFixed(2)}}</td>
                         <td>{{this.user.feedBackTest.prosek.istiNivo.toFixed(2)}}</td>
                         <td>{{this.user.feedBackTest.prosek.ukupno.toFixed(2)}}</td>
                      </tr>
                    </table> -->
                </div>
              </div>

              <table id="svaPitanja1">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>
                <tr>
                  <td>1.</td>
                  <td>
                    Za zadatke za koje niko u timu nije spreman da ih samostalno
                    obavi, pravovremeno bira zaposlenog koji pokazuje najveci
                    potencijal za samostalno obavljanje posla.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans1.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans1.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans1.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans1.podredjeni +
                          this.user.feedBackTest.ans1.nadredjeni +
                          this.user.feedBackTest.ans1.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>
                    Adekvatno bira kanal komunikacije (email, telefonski
                    razgovor, sastanak)u skladu sa temom i prirodom problema
                    kojim se bavi.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans2.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans2.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans2.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans2.podredjeni +
                          this.user.feedBackTest.ans2.nadredjeni +
                          this.user.feedBackTest.ans2.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>
                    Adekvatno se postavlja i reaguje na primedbe, zalbe i
                    kritike – uvažava tako dobijene informacije i nastoji da na
                    osnovu njih unapredi svoj pristup poslu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans3.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans3.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans3.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans3.podredjeni +
                          this.user.feedBackTest.ans3.nadredjeni +
                          this.user.feedBackTest.ans3.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>
                    Afirmiše zaposlene u svom timu, podsticuci ih na
                    samostalnost u radu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans4.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans4.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans4.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans4.podredjeni +
                          this.user.feedBackTest.ans4.nadredjeni +
                          this.user.feedBackTest.ans4.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>
                    Aktivno slusa sagovornika u komunikaciji, a zatim proverava
                    da li je dobro razumeo sagovornika, kako bi obezbedio
                    razumevanje u komunikaciji.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans5.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans5.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans5.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans5.podredjeni +
                          this.user.feedBackTest.ans5.nadredjeni +
                          this.user.feedBackTest.ans5.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>
                    Analizira greske u radu na delegiranim zadacima i evaluira
                    spremnost zaposlenih za uspešno realizovanje zadatka
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans6.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans6.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans6.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans6.podredjeni +
                          this.user.feedBackTest.ans6.nadredjeni +
                          this.user.feedBackTest.ans6.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>
                    Analizira zadatke iz perspektive zahtevanih resursa (vreme,
                    ljudi, finansije, materijalni resursi) za njegovo obavljanje
                    i vrsi adekvatnu pripremu tima.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans7.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans7.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans7.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans7.podredjeni +
                          this.user.feedBackTest.ans7.nadredjeni +
                          this.user.feedBackTest.ans7.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>
                    Bez obzira na prirodu teme i sagovornika (bilo da je
                    komunikacija prijatna ili neprijatna), u komunikaciji
                    zadrzava stav Ja sam ok, Ti si ok.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans8.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans8.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans8.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans8.podredjeni +
                          this.user.feedBackTest.ans8.nadredjeni +
                          this.user.feedBackTest.ans8.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja2">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>
                <tr>
                  <td>9.</td>
                  <td>
                    Daje zaposlenima izazovne zadatke tako da im omogucuje
                    prostor za razvoj.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans9.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans9.nadredjeni.toFixed(2) }}
                  </td>
                  <td>{{ this.user.feedBackTest.ans9.istiNivo.toFixed(2) }}</td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans9.podredjeni +
                          this.user.feedBackTest.ans9.nadredjeni +
                          this.user.feedBackTest.ans9.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>10.</td>
                  <td>
                    Delegira zadatke onim zaposlenima koji su spremni da ih
                    samostalno realizuju obezbedjujuci licnu efikasnost i
                    efikasnost organizacije
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans10.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans10.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans10.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans10.podredjeni +
                          this.user.feedBackTest.ans10.nadredjeni +
                          this.user.feedBackTest.ans10.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td>
                    Delegira zadatke vodeci racuna i o sposobnosti i o
                    motivaciji zaposlenog za taj zadatak.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans11.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans11.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans11.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans11.podredjeni +
                          this.user.feedBackTest.ans11.nadredjeni +
                          this.user.feedBackTest.ans11.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>12.</td>
                  <td>
                    Deli informacije, daje adekvatne savete sa ciljem pruzi
                    podrsku drugima u uspesnom obavljanju posla.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans12.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans12.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans12.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans12.podredjeni +
                          this.user.feedBackTest.ans12.nadredjeni +
                          this.user.feedBackTest.ans12.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>13.</td>
                  <td>
                    Demonstrira spremnost da donese odluku koja negativno utice
                    na pojedince u timu ili deo poslovanja zarad ostvarivanja
                    visih kompanijskih ciljeva.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans13.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans13.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans13.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans13.podredjeni +
                          this.user.feedBackTest.ans13.nadredjeni +
                          this.user.feedBackTest.ans13.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>14.</td>
                  <td>
                    Donosi odluke kroz uporedjivanje informacija iz vise
                    razlicitih izvora i predvidjanje posledica.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans14.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans14.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans14.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans14.podredjeni +
                          this.user.feedBackTest.ans14.nadredjeni +
                          this.user.feedBackTest.ans14.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>15.</td>
                  <td>
                    Drzi fokus zaposlenih koriscenjem sansi za buduce uspehe i
                    rezultate, umesto na zaslugama za rezultate u proslosti.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans15.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans15.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans15.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans15.podredjeni +
                          this.user.feedBackTest.ans15.nadredjeni +
                          this.user.feedBackTest.ans15.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>16.</td>
                  <td>Govori jasno.</td>
                  <td>
                    {{ this.user.feedBackTest.ans16.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans16.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans16.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans16.podredjeni +
                          this.user.feedBackTest.ans16.nadredjeni +
                          this.user.feedBackTest.ans16.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>17.</td>
                  <td>
                    Identifikuje i razume izazove, probleme, sanse i donosi
                    efektivne odluke o pravcu aktivnosti koje zeli da sprovode.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans17.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans17.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans17.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans17.podredjeni +
                          this.user.feedBackTest.ans17.nadredjeni +
                          this.user.feedBackTest.ans17.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>18.</td>
                  <td>
                    Interesuje se za potrebe i zelje zaposlenih i preduzima
                    aktivnosti u skladu sa tim.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans18.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans18.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans18.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans18.podredjeni +
                          this.user.feedBackTest.ans18.nadredjeni +
                          this.user.feedBackTest.ans18.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja3">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>
                <tr>
                  <td>19.</td>
                  <td>
                    Iznenadnim (ad hoc) zadacima pristupa konstruktivno,
                    preuzima zadatak na sebe i odredjuje rok ili preuzeti
                    zadatak delegira unutar tima
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans19.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans19.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans19.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans19.podredjeni +
                          this.user.feedBackTest.ans19.nadredjeni +
                          this.user.feedBackTest.ans19.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>20.</td>
                  <td>
                    Izrazava poverenje u sposobnost zaposlenih da obave zadatak,
                    tako što im pruza pomoc kada je traze, i brzo nakon toga im
                    vraca odgovornost za zadatak.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans20.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans20.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans20.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans20.podredjeni +
                          this.user.feedBackTest.ans20.nadredjeni +
                          this.user.feedBackTest.ans20.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>21.</td>
                  <td>
                    Jasno stavlja do znanja koji su parametri delegiranih
                    odgovornosti, ukljucujuci i davanje autoriteta za donosenje
                    odluka i preduzimanje potrebnih akcija.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans21.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans21.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans21.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans21.podredjeni +
                          this.user.feedBackTest.ans21.nadredjeni +
                          this.user.feedBackTest.ans21.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>22.</td>
                  <td>
                    Kada komunicira u pisanom obliku, daje pregledne i
                    razumljive informacije.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans22.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans22.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans22.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans22.podredjeni +
                          this.user.feedBackTest.ans22.nadredjeni +
                          this.user.feedBackTest.ans22.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>23.</td>
                  <td>
                    Kada komunicira usmeno gleda sagovornika u oci i pokazuje
                    uvazavanje svojim telesnim stavom.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans23.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans23.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans23.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans23.podredjeni +
                          this.user.feedBackTest.ans23.nadredjeni +
                          this.user.feedBackTest.ans23.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>24.</td>
                  <td>
                    Konstantno trazi nacine da kreira nove i inovativne nacine
                    unapredjenja organizacije i dolazenja do cilja.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans24.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans24.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans24.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans24.podredjeni +
                          this.user.feedBackTest.ans24.nadredjeni +
                          this.user.feedBackTest.ans24.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>25.</td>
                  <td>
                    Kritiku upucuje na ponasanje, zadrzavajuci pozitivan
                    kontekst uvazavanja zaposlenog.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans25.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans25.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans25.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans25.podredjeni +
                          this.user.feedBackTest.ans25.nadredjeni +
                          this.user.feedBackTest.ans25.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>26.</td>
                  <td>Kritiku upucuje pravovremeno, bez odlaganja.</td>
                  <td>
                    {{ this.user.feedBackTest.ans26.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans26.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans26.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans26.podredjeni +
                          this.user.feedBackTest.ans26.nadredjeni +
                          this.user.feedBackTest.ans26.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>27.</td>
                  <td>
                    Manje vremena provodi objasnjavajuci svoje vrednosti, vise
                    vremena provodi ponasajuci se u skladu sa njima.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans27.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans27.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans27.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans27.podredjeni +
                          this.user.feedBackTest.ans27.nadredjeni +
                          this.user.feedBackTest.ans27.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja4">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>
                <tr>
                  <td>28.</td>
                  <td>
                    Na "lose" vesti reaguje kao na sansu za unapredjenje, ne
                    pokazuje pasivnost i nesposobnost za reakciju.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans28.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans28.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans28.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans28.podredjeni +
                          this.user.feedBackTest.ans28.nadredjeni +
                          this.user.feedBackTest.ans28.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>29.</td>
                  <td>
                    Na pocetku nedelje rasporedjuje kljucne zadatke zaposlenima.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans29.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans29.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans29.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans29.podredjeni +
                          this.user.feedBackTest.ans29.nadredjeni +
                          this.user.feedBackTest.ans29.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>30.</td>
                  <td>
                    Nakon delegiranja zadataka organizuje sastanke na kojima ce
                    pratiti njihovu realizaciju.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans30.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans30.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans30.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans30.podredjeni +
                          this.user.feedBackTest.ans30.nadredjeni +
                          this.user.feedBackTest.ans30.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>31.</td>
                  <td>
                    Nakon delegiranja zadatka odredjuje koje informacije o
                    izvrsenju zadatka ce pratiti.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans31.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans31.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans31.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans31.podredjeni +
                          this.user.feedBackTest.ans31.nadredjeni +
                          this.user.feedBackTest.ans31.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>32.</td>
                  <td>
                    Nakon delegiranja, obezbedjuje adekvatnu podrsku zaposlenom
                    u radu na zadatku ukljucujuci se kao facilitator i izvor
                    neophodnih informacija.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans32.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans32.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans32.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans32.podredjeni +
                          this.user.feedBackTest.ans32.nadredjeni +
                          this.user.feedBackTest.ans32.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>33.</td>
                  <td>
                    Obezbedjuje vidljivost veze izmedju nagrada i postignutih
                    rezultata zaposlenih.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans33.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans33.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans33.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans33.podredjeni +
                          this.user.feedBackTest.ans33.nadredjeni +
                          this.user.feedBackTest.ans33.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>34.</td>
                  <td>Obucava druge u procesu razvoja njihovih vestina.</td>
                  <td>
                    {{ this.user.feedBackTest.ans34.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans34.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans34.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans34.podredjeni +
                          this.user.feedBackTest.ans34.nadredjeni +
                          this.user.feedBackTest.ans34.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>35.</td>
                  <td>
                    Odluke donosi pravovremeno i zastupa ih sa jasnom
                    argumentacijom.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans35.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans35.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans35.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans35.podredjeni +
                          this.user.feedBackTest.ans35.nadredjeni +
                          this.user.feedBackTest.ans35.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>36.</td>
                  <td>
                    Odluke koje su viseg ili nizeg nivoa pravovremeno prenosi
                    odgovarajucem donosiocu odluke.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans36.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans36.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans36.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans36.podredjeni +
                          this.user.feedBackTest.ans36.nadredjeni +
                          this.user.feedBackTest.ans36.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>37.</td>
                  <td>
                    Odrzava adekvatne medjuljudske odnose i kada je pod
                    pritiskom.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans37.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans37.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans37.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans37.podredjeni +
                          this.user.feedBackTest.ans37.nadredjeni +
                          this.user.feedBackTest.ans37.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>38.</td>
                  <td>
                    Organizuje posao tako da se vrednuju saradnja i postignuti
                    ciljevi.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans38.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans38.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans38.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans38.podredjeni +
                          this.user.feedBackTest.ans38.nadredjeni +
                          this.user.feedBackTest.ans38.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>39.</td>
                  <td>
                    Osigurava razumevanje u komunikaciji tako sto proverava sa
                    sagovornikom da li razume poruku.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans39.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans39.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans39.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans39.podredjeni +
                          this.user.feedBackTest.ans39.nadredjeni +
                          this.user.feedBackTest.ans39.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja5">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>
                <tr>
                  <td>40.</td>
                  <td>
                    Otvoreno priznaje i naglasava zalaganje i doprinos
                    pojedinaca ukljucenih u rad tima.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans40.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans40.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans40.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans40.podredjeni +
                          this.user.feedBackTest.ans40.nadredjeni +
                          this.user.feedBackTest.ans40.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>41.</td>
                  <td>
                    Planira na mesecnom nivou tako sto pravi preglede kljucnih
                    aktivnosti na nivou meseca.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans41.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans41.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans41.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans41.podredjeni +
                          this.user.feedBackTest.ans41.nadredjeni +
                          this.user.feedBackTest.ans41.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>42.</td>
                  <td>
                    Pokazuje licnu hrabrost da povede ljude oko sebe u jasno
                    odredjenom pravcu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans42.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans42.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans42.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans42.podredjeni +
                          this.user.feedBackTest.ans42.nadredjeni +
                          this.user.feedBackTest.ans42.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>43.</td>
                  <td>
                    Pokazuje spremnost na dogovor u situacijama kada se ne slaze
                    sa sagovornikom.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans43.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans43.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans43.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans43.podredjeni +
                          this.user.feedBackTest.ans43.nadredjeni +
                          this.user.feedBackTest.ans43.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>44.</td>
                  <td>
                    Pomaze drugima u korigovanju njihove komunikacije, kako bi
                    podigao standard komunikacije u kompaniji.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans44.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans44.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans44.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans44.podredjeni +
                          this.user.feedBackTest.ans44.nadredjeni +
                          this.user.feedBackTest.ans44.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>45.</td>
                  <td>
                    Postavlja jasne timske prioritete, objasnjava znacaj i
                    postavlja precizne ciljeve i zadatke za koje je tim
                    odgovoran.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans45.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans45.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans45.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans45.podredjeni +
                          this.user.feedBackTest.ans45.nadredjeni +
                          this.user.feedBackTest.ans45.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>46.</td>
                  <td>Postavlja nedeljne ciljeve na pocetku nedelje.</td>
                  <td>
                    {{ this.user.feedBackTest.ans46.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans46.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans46.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans46.podredjeni +
                          this.user.feedBackTest.ans46.nadredjeni +
                          this.user.feedBackTest.ans46.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>47.</td>
                  <td>
                    Prati promenu situacija i u skladu sa novoprispelim podacima
                    u pravom trenutku vrsi promenu odluke.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans47.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans47.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans47.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans47.podredjeni +
                          this.user.feedBackTest.ans47.nadredjeni +
                          this.user.feedBackTest.ans47.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>48.</td>
                  <td>
                    Prati rad na realizaciji zadatka tako sto osigurava
                    efikasnost i balansira resurse u skladu sa eventualnim
                    promenama situacije ili okolnosti.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans48.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans48.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans48.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans48.podredjeni +
                          this.user.feedBackTest.ans48.nadredjeni +
                          this.user.feedBackTest.ans48.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>49.</td>
                  <td>
                    Pravi akcione planove za realizaciju posla u kojima se jasno
                    vide odgovornosti i ciljevi rada svake ukljucene osobe.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans49.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans49.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans49.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans49.podredjeni +
                          this.user.feedBackTest.ans49.nadredjeni +
                          this.user.feedBackTest.ans49.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja6">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>

                <tr>
                  <td>50.</td>
                  <td>
                    Pravi realisticne dugorocne/srednjorocne planove rada.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans50.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans50.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans50.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans50.podredjeni +
                          this.user.feedBackTest.ans50.nadredjeni +
                          this.user.feedBackTest.ans50.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>51.</td>
                  <td>
                    Pravovremeno (jos tokom planiranja aktivnosti) obavestava
                    druge koji su ukljuceni u aktivnost o vremenu realizacije
                    njihovih zadataka i vrsti posla u koji se ukljucuju.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans51.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans51.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans51.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans51.podredjeni +
                          this.user.feedBackTest.ans51.nadredjeni +
                          this.user.feedBackTest.ans51.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>52.</td>
                  <td>
                    Pravovremeno koristi pohvalu i kritiku, tako da zaposleni
                    imaju dozivljaj i postovanja, i pracenja i vrednovanja
                    njihovog rada.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans52.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans52.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans52.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans52.podredjeni +
                          this.user.feedBackTest.ans52.nadredjeni +
                          this.user.feedBackTest.ans52.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>53.</td>
                  <td>
                    Pravovremeno obavestava nadredjene kad neadekvatni rezultati
                    aktivnosti ugrozavaju ispunjavanje cilja na nacin da on
                    lično ili tim nemaju kapacitet da rese situaciju.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans53.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans53.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans53.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans53.podredjeni +
                          this.user.feedBackTest.ans53.nadredjeni +
                          this.user.feedBackTest.ans53.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>54.</td>
                  <td>
                    Pravovremeno ulazi u komunikaciju koja ima stresnu prirodu
                    (niti je odlaze, niti ulazi naglo, pre postavke cilje)
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans54.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans54.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans54.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans54.podredjeni +
                          this.user.feedBackTest.ans54.nadredjeni +
                          this.user.feedBackTest.ans54.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>55.</td>
                  <td>
                    Precizno identifikuje jake strane i oblasti za razvoj
                    drugih.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans55.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans55.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans55.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans55.podredjeni +
                          this.user.feedBackTest.ans55.nadredjeni +
                          this.user.feedBackTest.ans55.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>56.</td>
                  <td>
                    Predlaze konstruktivna resenja problema i trazi odgovornost
                    za resenje problema.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans56.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans56.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans56.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans56.podredjeni +
                          this.user.feedBackTest.ans56.nadredjeni +
                          this.user.feedBackTest.ans56.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>57.</td>
                  <td>
                    Preduzima potrebne akcije kako bi se donesena odluka
                    sprovela u realnost.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans57.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans57.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans57.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans57.podredjeni +
                          this.user.feedBackTest.ans57.nadredjeni +
                          this.user.feedBackTest.ans57.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>58.</td>
                  <td>
                    Prepoznaje koje odluke su u njenoj nadleznosti I preuzima
                    odgovornost za njih.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans58.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans58.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans58.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans58.podredjeni +
                          this.user.feedBackTest.ans58.nadredjeni +
                          this.user.feedBackTest.ans58.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>59.</td>
                  <td>
                    Prilikom delegiranja zadataka proverava da li je zaposleni
                    razumeo sve parametre delegiranog zadatka.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans59.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans59.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans59.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans59.podredjeni +
                          this.user.feedBackTest.ans59.nadredjeni +
                          this.user.feedBackTest.ans59.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja7">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>

                <tr>
                  <td>60.</td>
                  <td>
                    Prioritete u radu odredjuje pravilno u odnosu na objektivne
                    zahteve posla.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans60.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans60.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans60.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans60.podredjeni +
                          this.user.feedBackTest.ans60.nadredjeni +
                          this.user.feedBackTest.ans60.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>61.</td>
                  <td>
                    Razmatra sta u okruzenju predstavlja prepreku, a sta pomaze
                    razvoju i organizuje celokupno okruzenje tako da podstice
                    razvoj i nagradjuje pozitivnu promenu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans61.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans61.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans61.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans61.podredjeni +
                          this.user.feedBackTest.ans61.nadredjeni +
                          this.user.feedBackTest.ans61.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>62.</td>
                  <td>
                    Razume da se oko njega ljudi razlikuju prema onome sta ih
                    dodatno motivise i tim saznanjima se rukovodi u procesu
                    podsticanja na ostvarivanje rezultata.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans62.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans62.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans62.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans62.podredjeni +
                          this.user.feedBackTest.ans62.nadredjeni +
                          this.user.feedBackTest.ans62.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>63.</td>
                  <td>
                    Razvija direktno podredjene za obavljanje novih zadataka.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans63.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans63.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans63.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans63.podredjeni +
                          this.user.feedBackTest.ans63.nadredjeni +
                          this.user.feedBackTest.ans63.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>64.</td>
                  <td>
                    Redovno sa svakim zaposlenim u svom timu definise razvojne
                    ciljeve i evaluira ucinke.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans64.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans64.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans64.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans64.podredjeni +
                          this.user.feedBackTest.ans64.nadredjeni +
                          this.user.feedBackTest.ans64.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>65.</td>
                  <td>
                    Rukovodi licnim primerom - ponasa se onako kako bi voleo da
                    se i ostali oko njega ponasaju.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans65.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans65.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans65.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans65.podredjeni +
                          this.user.feedBackTest.ans65.nadredjeni +
                          this.user.feedBackTest.ans65.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>66.</td>
                  <td>
                    Sa lakocom gradi zajednicku viziju buducnosti sa ljudima
                    koji ga okruzuju.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans66.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans66.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans66.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans66.podredjeni +
                          this.user.feedBackTest.ans66.nadredjeni +
                          this.user.feedBackTest.ans66.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>67.</td>
                  <td>
                    Sadrzaj koji prenosi u komunikaciji obuhvata uvek relevantne
                    i sustinske ideje, sa optimalnim nivoom detalja.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans67.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans67.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans67.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans67.podredjeni +
                          this.user.feedBackTest.ans67.nadredjeni +
                          this.user.feedBackTest.ans67.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>68.</td>
                  <td>
                    Sistematski nastoji da se obezbedi isticanje i nagradjivanje
                    ispunjenja ciljeva.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans68.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans68.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans68.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans68.podredjeni +
                          this.user.feedBackTest.ans68.nadredjeni +
                          this.user.feedBackTest.ans68.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja8">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>

                <tr>
                  <td>69.</td>
                  <td>
                    Sposoban je da adekvatno upravlja motivacijom zaposlenih
                    tako sto prepoznaje i razume tudja osecanja i teskoce, kao i
                    drugacije perspektive i razlicitosti.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans69.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans69.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans69.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans69.podredjeni +
                          this.user.feedBackTest.ans69.nadredjeni +
                          this.user.feedBackTest.ans69.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>70.</td>
                  <td>
                    Sposoban je da radi paralelno i sistematski na vise
                    zadataka, ne gubeci fokus i kontrolu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans70.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans70.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans70.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans70.podredjeni +
                          this.user.feedBackTest.ans70.nadredjeni +
                          this.user.feedBackTest.ans70.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>71.</td>
                  <td>
                    Stalno je informisan - uspostavlja procedure da bi
                    permanentno bio informisan o izazovima i rezultatima
                    delegiranih zadataka.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans71.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans71.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans71.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans71.podredjeni +
                          this.user.feedBackTest.ans71.nadredjeni +
                          this.user.feedBackTest.ans71.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>72.</td>
                  <td>
                    Stvara atmosferu u kojoj svako daje vise od onoga što je
                    mislio da moze.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans72.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans72.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans72.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans72.podredjeni +
                          this.user.feedBackTest.ans72.nadredjeni +
                          this.user.feedBackTest.ans72.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>73.</td>
                  <td>
                    Sumira i rezimira glavne ideje i zakljucke na kraju
                    komunikacije.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans73.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans73.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans73.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans73.podredjeni +
                          this.user.feedBackTest.ans73.nadredjeni +
                          this.user.feedBackTest.ans73.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>74.</td>
                  <td>
                    Svestan je potencijalnog rizika za odluku koju je doneo i
                    preuzima odgovornost za prevenciju istih.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans74.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans74.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans74.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans74.podredjeni +
                          this.user.feedBackTest.ans74.nadredjeni +
                          this.user.feedBackTest.ans74.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>75.</td>
                  <td>
                    Svestan je svoje uloge u kompaniji (sta kompanija od njega
                    ocekuje) i ponasa se u skladu sa tim.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans75.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans75.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans75.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans75.podredjeni +
                          this.user.feedBackTest.ans75.nadredjeni +
                          this.user.feedBackTest.ans75.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>76.</td>
                  <td>Trazi fidbek da bi poboljšao ucinak.</td>
                  <td>
                    {{ this.user.feedBackTest.ans76.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans76.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans76.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans76.podredjeni +
                          this.user.feedBackTest.ans76.nadredjeni +
                          this.user.feedBackTest.ans76.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>77.</td>
                  <td>
                    Trazi jasne i konkretne informacije o zadatku koji preuzima
                    na sebe.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans77.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans77.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans77.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans77.podredjeni +
                          this.user.feedBackTest.ans77.nadredjeni +
                          this.user.feedBackTest.ans77.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>78.</td>
                  <td>
                    U komuniikaciji unapred postavlja cilj koji je 'zdrav',
                    odnosno u interesu svih strana u komunikaciji.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans78.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans78.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans78.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans78.podredjeni +
                          this.user.feedBackTest.ans78.nadredjeni +
                          this.user.feedBackTest.ans78.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>79.</td>
                  <td>
                    U komunikaciji na poslu koristi recnik, terminologiju i
                    nacin govora koji odgovara poslovnom kontekstu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans79.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans79.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans79.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans79.podredjeni +
                          this.user.feedBackTest.ans79.nadredjeni +
                          this.user.feedBackTest.ans79.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja9">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>

                <tr>
                  <td>80.</td>
                  <td>
                    U najrazlicitijim situacijama reaguje na nacin koji
                    adekvatno utice na druge – ne prepusta se trenutnom impulsu
                    vec daje primer konstruktivnog pristupa situacijama.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans80.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans80.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans80.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans80.podredjeni +
                          this.user.feedBackTest.ans80.nadredjeni +
                          this.user.feedBackTest.ans80.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>81.</td>
                  <td>
                    U sklopu planiranja kalkulise i moguce razlicite scenarije
                    dogadjanja, tako i planira više alternativnih nacina za
                    ostvarivanje cilja.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans81.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans81.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans81.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans81.podredjeni +
                          this.user.feedBackTest.ans81.nadredjeni +
                          this.user.feedBackTest.ans81.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>82.</td>
                  <td>
                    U slucaju odstupanja u realizaciji zadataka pravovremeno
                    vrsi poostravanje kontrole kako bi se ostalo na zacrtanom
                    kursu.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans82.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans82.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans82.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans82.podredjeni +
                          this.user.feedBackTest.ans82.nadredjeni +
                          this.user.feedBackTest.ans82.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>83.</td>
                  <td>
                    U susretu sa problemima prvo se fokusira na trazenje
                    resenja, umesto na trazenje krivca.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans83.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans83.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans83.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans83.podredjeni +
                          this.user.feedBackTest.ans83.nadredjeni +
                          this.user.feedBackTest.ans83.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>84.</td>
                  <td>
                    Ukoliko nema adekvatne resurse, ne prihvata delegirani
                    zadatak uz jasnu argumentaciju i daje eventualne smernice za
                    obavljanje zadatka koje su korisne naruciocu zadatka.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans84.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans84.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans84.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans84.podredjeni +
                          this.user.feedBackTest.ans84.nadredjeni +
                          this.user.feedBackTest.ans84.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>85.</td>
                  <td>
                    Uporedjuje rezultate i ishode delegiranih zadataka u odnosu
                    na definisane planove.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans85.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans85.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans85.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans85.podredjeni +
                          this.user.feedBackTest.ans85.nadredjeni +
                          this.user.feedBackTest.ans85.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>86.</td>
                  <td>
                    Upostavlja posvecenost ljudi u kompaniji - podstice
                    entuzijazam drugih sa kojima radi na zadatku.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans86.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans86.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans86.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans86.podredjeni +
                          this.user.feedBackTest.ans86.nadredjeni +
                          this.user.feedBackTest.ans86.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>87.</td>
                  <td>
                    Usmerava komunikaciju tako sto daje relevatne informacije,
                    razjasnjava svrhu i znacaj teme.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans87.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans87.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans87.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans87.podredjeni +
                          this.user.feedBackTest.ans87.nadredjeni +
                          this.user.feedBackTest.ans87.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>88.</td>
                  <td>
                    Za relevatne ciljeve i projekte pravi detaljne akcione
                    planove.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans88.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans88.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans88.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans88.podredjeni +
                          this.user.feedBackTest.ans88.nadredjeni +
                          this.user.feedBackTest.ans88.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>89.</td>
                  <td>
                    Zadatke delegira precizno, tako sto saopstava kriterijume
                    uspeha na zadatku, kljucne oblasti zadatka i vremenske
                    okvire za izvrsenje.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans89.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans89.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans89.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans89.podredjeni +
                          this.user.feedBackTest.ans89.nadredjeni +
                          this.user.feedBackTest.ans89.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>

              <table id="svaPitanja10">
                <tr>
                  <th>R.b.</th>
                  <th>Pitanje</th>
                  <th>Podredjeni</th>
                  <th>Nadredjeni</th>
                  <th>Isti Nivo</th>
                  <th>Ukupno</th>
                </tr>

                <tr>
                  <td>90.</td>
                  <td>
                    Zapisuje sve novodobijene zadatke odmah, i odmah precizira
                    vreme kada ce ih realizovati.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans90.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans90.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans90.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans90.podredjeni +
                          this.user.feedBackTest.ans90.nadredjeni +
                          this.user.feedBackTest.ans90.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>91.</td>
                  <td>
                    Zaposleni u njegovom timu poznaju kriterijume koje je
                    potrebno da zadovolje kako bi bili nagradjeni za svoj rad.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans91.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans91.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans91.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans91.podredjeni +
                          this.user.feedBackTest.ans91.nadredjeni +
                          this.user.feedBackTest.ans91.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>

                <tr>
                  <td>92.</td>
                  <td>
                    Zna kada da vodi i obucava druge, a kada da ih pusti da rade
                    sami.
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans92.podredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans92.nadredjeni.toFixed(2) }}
                  </td>
                  <td>
                    {{ this.user.feedBackTest.ans92.istiNivo.toFixed(2) }}
                  </td>
                  <td>
                    {{
                      (
                        (this.user.feedBackTest.ans92.podredjeni +
                          this.user.feedBackTest.ans92.nadredjeni +
                          this.user.feedBackTest.ans92.istiNivo) /
                        3
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </table>
            </div>

            <button @click="makePDF360">MakePDF</button>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThreeX">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseX"
          data-bs-parent="#accordionExample"
          aria-expanded="false"
          aria-controls="collapseX"
        >
          Psihološka procena osobina ličnosti i radnih vrednosti
        </button>
      </h2>
      <div
        id="collapseX"
        class="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div class="">
            <div class="procenaElement">
              <h4>Opšta ocena:</h4>
              <textarea
                class="form-control"
                id="opstaOcenaFormControl"
                rows="10"
                v-model="procenaOpstaOcena"
              ></textarea>
            </div>

            <div class="procenaElement">
              <h4>Pozitivno:</h4>
              <div
                class="form-check"
                v-for="(element, i) in procenaPozitivno"
                v-bind:key="i"
              >
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="procenaPozitivno[i]"
                  spellcheck="false"
                ></textarea>
                <button
                  class="left"
                  type="button"
                  name="button"
                  @click="ukloniPozitivno(i)"
                >
                  ukloni
                </button>
              </div>
              <button
                class="left"
                type="button"
                name="button"
                @click="increasePozitivno()"
              >
                Dodaj
              </button>
            </div>

            <div class="procenaElement">
              <h4>Negativno:</h4>
              <div
                class="form-check"
                v-for="(element, i) in procenaNegativno"
                v-bind:key="i"
              >
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="procenaNegativno[i]"
                  spellcheck="false"
                ></textarea>
                <button
                  class="left"
                  type="button"
                  name="button"
                  @click="ukloniNegativno(i)"
                >
                  ukloni
                </button>
              </div>
              <button
                class="left"
                type="button"
                name="button"
                @click="increaseNegativno()"
              >
                Dodaj
              </button>
            </div>

            <div class="procenaElement">
              <h4>Mišljenje psihologa:</h4>
              <textarea
                class="form-control"
                id="opstaOcenaFormControl"
                rows="10"
                v-model="procenaMisljenjePsihologa"
              ></textarea>
            </div>

            <div class="procenaElement">
              <h4>VQ test</h4>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="savedVq"
                id="vqCheck"
              />
              <label for="vqCheck"
                >Koristi ovaj VQ u izvestaju (ukoliko nije "check-irano" ucitace
                se VQ test iz baze)</label
              >

              <h5>Vq zaglavlje:</h5>
              <textarea
                class="form-control"
                id="vqheader"
                rows="2"
                v-model="vqHeader"
              ></textarea>
              <h5>Vq tekst:</h5>
              <textarea
                class="form-control"
                id="vqBody"
                rows="10"
                v-model="vqBody"
              ></textarea>
            </div>

            <div class="validation">
              {{ validationPsih }}
            </div>
            <button
              class="left"
              type="button"
              name="button"
              @click="submitProcena()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="telo"></div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import html2canvas from "html2canvas";
// eslint-disable-next-line no-unused-vars
import jsPDF from "jspdf";
import "./../assets/Merriweather-Regular-normal";

// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
import ProcenaKandidata from "@/components/ProcenaKandidataComponent.vue";
import CustomProcena from "@/components/CustomProcenaComponent.vue";
export default {
  emits: ["addPerEval"],
  components: {
    ProcenaKandidata,
    CustomProcena,
  },
  computed: mapGetters(["companys", "psiholoskaProcenas", "usersSameCompany"]),
  props: {
    user: {
      type: Object,
    },
    companyConnector: {
      type: Number,
    },
    selectedCompanyCustomProcena: {
      type: Array,
    },
  },
  data() {
    return {
      letterSpacing: false,
      companyKpiConnector: 0,
      vqHeader: "",
      vqBody: "",
      savedVq: false,
      validationPsih: "",
      randomNumber: 0,
      vqDate: "",
      testLicnostiDate: "",
      belbinNajvisiPrvi: 0,
      belbinNajvisiDrugi: 0,
      belbinNajnizi: 0,
      kompetenceOcena: 0,
      licniOcena: 0,
      kompanijskiOcena: 0,
      ukupnoKpi: 0,
      kompetenceProc: 0,
      licniProc: 0,
      licniKpiTitle: "Licni KPI",
      kompanijskiProc: 0,
      kompetenceBool: [],
      licniBool: [],
      kompanijskiBool: [],
      lastEval: 0,
      evalEmpty: true,
      procenaDate: "",
      CurrentDate: "",
      date: [],
      kompetence: [],
      licniKpi: [],
      kompanijskiKpi: [],
      konacnaProcena: "",
      samoProcena: "",

      procenaOpstaOcena: "",
      procenaPozitivno: [],
      procenaNegativno: [],
      procenaMisljenjePsihologa: "",

      N1: 0,
      N2: 0,
      N3: 0,
      N4: 0,
      N5: 0,
      N6: 0,

      E1: 0,
      E2: 0,
      E3: 0,
      E4: 0,
      E5: 0,
      E6: 0,

      O1: 0,
      O2: 0,
      O3: 0,
      O4: 0,
      O5: 0,
      O6: 0,

      U1: 0,
      U2: 0,
      U3: 0,
      U4: 0,
      U5: 0,
      U6: 0,

      S1: 0,
      S2: 0,
      S3: 0,
      S4: 0,
      S5: 0,
      S6: 0,
      chartDataVq: {
        A: 0,
        B: 0,
      },

      chartData: {
        NEUROTICIZAM: 0,
        EKSTRAVERZIJA: 0,
        OTVORENOST: 0,
        SARADLJIVOST: 0,
        SAVESNOST: 0,
      },
      chartDataDetails: {
        Anksioznost: 0,
        Hostilnost: 0,
        Depresivnost: 0,
        Socijalna_nelagodnost: 0,
        Impulzivnost: 0,
        Vulnerabilnost: 0,

        Toplina: 0,
        Druželjubivost: 0,
        Asertivnost: 0,
        Aktivnost: 0,
        Traženje_uzbuđenja: 0,
        Pozitivne_emocije: 0,

        Fantaziranje: 0,
        Estetika: 0,
        Osećanja: 0,
        Akcija: 0,
        Ideje: 0,
        Vrijednosti: 0,

        Povjerenje: 0,
        Iskrenost: 0,
        Altruizam: 0,
        Popustljivost: 0,
        Skromnost: 0,
        Blaga_narav: 0,

        Kompetentnost: 0,
        Organiziranost: 0,
        Odgovornost: 0,
        Težnja_za_postignućem: 0,
        Samodisciplina: 0,
        Promišljenost: 0,
      },
      // eslint-disable-next-line no-unused-vars

      chartOptionsDetailed: {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 32,
                min: 0,
              },
            },
          ],
        },
      },

      chartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                max: 192,
                min: 0,
              },
            },
          ],
        },
      },
    };
  },
  async created() {
    this.getCompanies();

    if (this.user.performanceEvaluation.length > 0) {
      this.lastEval = this.user.performanceEvaluation.length - 1;
      this.evalEmpty = false;
      this.procenaDate = this.user.performanceEvaluation[this.lastEval].date;
      this.CurrentDate = this.user.performanceEvaluation[this.lastEval].date;
      this.companyKpiConnector = this.user.performanceEvaluation[
        this.lastEval
      ].companyKpiConnector;
    }
    ///////////KPI NOT EMPTY
    if (!this.evalEmpty) {
      this.konacnaProcena = this.user.performanceEvaluation[
        this.lastEval
      ].komentar;
      this.samoProcena = this.user.performanceEvaluation[
        this.lastEval
      ].komentarSamoProcena;

      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompetence.length;
        i++
      ) {
        let emptyObj = {};
        this.kompetence.push(emptyObj);
        this.kompetence[i].samoProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].samoProcena;
        this.kompetence[i].konacnaProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].konacnaProcena;
        this.kompetence[i].rangiranje = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].rangiranje;
        this.kompetence[i].name = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].name;
        this.kompetence[i].ponder = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].ponder;
      }
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompanijski.length;
        i++
      ) {
        let emptyObj = {};
        this.kompanijskiKpi.push(emptyObj);
        this.kompanijskiKpi[i].samoProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].samoProcena;
        this.kompanijskiKpi[i].konacnaProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].konacnaProcena;
        this.kompanijskiKpi[i].rangiranje = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].rangiranje;
        this.kompanijskiKpi[i].name = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].name;
        this.kompanijskiKpi[i].ponder = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].ponder;
      }
      this.licniKpi = [];
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].licniKpi.length;
        i++
      ) {
        this.licniKpi.push(
          this.user.performanceEvaluation[this.lastEval].licniKpi[i]
        );
      }

      if (
        this.user.performanceEvaluation[this.lastEval].licniKpiTitle !=
        undefined
      ) {
        this.licniKpiTitle = this.user.performanceEvaluation[
          this.lastEval
        ].licniKpiTitle;
      } else {
        this.licniKpiTitle = "Licni KPI";
      }

      for (i = 0; i < this.user.performanceEvaluation.length; i++) {
        this.date.push(this.user.performanceEvaluation[i].date);
      }

      let tempCompany = {};
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == this.user.type) {
          tempCompany = this.companys[i];
        }
      }

      let brojac = this.user.performanceEvaluation[this.lastEval].kompetence
        .length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment:
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompetence[i].comment,
        };
        this.kompetenceBool.push(komp);
      }
      brojac = this.user.performanceEvaluation[this.lastEval].kompanijski
        .length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment:
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompanijski[i].comment,
        };
        this.kompanijskiBool.push(komp);
      }
      brojac = this.user.performanceEvaluation[this.lastEval].licniKpi.length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment: this.user.performanceEvaluation[this.lastEval].licniKpi[i]
            .comment,
        };
        this.licniBool.push(komp);
      }

      //kompetence
      let ukupnoKompetence = 0;
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompetence.length;
        i++
      ) {
        if (
          this.user.performanceEvaluation[this.lastEval].kompetence[i]
            .konacnaProcena != null
        ) {
          ukupnoKompetence += parseFloat(
            this.user.performanceEvaluation[this.lastEval].kompetence[
              i
            ].rangiranje.toFixed(2)
          );
        }
      }
      this.kompetenceOcena = ukupnoKompetence;

      //licni
      let ukupnoLicni = 0;
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].licniKpi.length;
        i++
      ) {
        if (
          this.user.performanceEvaluation[this.lastEval].licniKpi[i]
            .konacnaProcena != null
        ) {
          ukupnoLicni += parseFloat(
            this.user.performanceEvaluation[this.lastEval].licniKpi[
              i
            ].rangiranje.toFixed(2)
          );
        }
      }
      this.licniOcena = ukupnoLicni;
      //kompanijski
      let ukupnoKompanijski = 0;
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompanijski.length;
        i++
      ) {
        if (
          this.user.performanceEvaluation[this.lastEval].kompanijski[i]
            .konacnaProcena != null
        ) {
          ukupnoKompanijski += parseFloat(
            this.user.performanceEvaluation[this.lastEval].kompanijski[
              i
            ].rangiranje.toFixed(2)
          );
        }
      }
      this.kompanijskiOcena = ukupnoKompanijski;

      this.ukupnoKpi =
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .kompetenceProcenat /
          100) *
          ukupnoKompetence +
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .licniProcenat /
          100) *
          ukupnoLicni +
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .kompanijskiProcenat /
          100) *
          ukupnoKompanijski;

      this.kompetenceProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].kompetenceProcenat;
      this.licniProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].licniProcenat;
      this.kompanijskiProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].kompanijskiProcenat;
    }
    ///////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////
    let belbinNiz = [];

    belbinNiz.push(this.user.belbinTest.SO);
    belbinNiz.push(this.user.belbinTest.RV);
    belbinNiz.push(this.user.belbinTest.OA);
    belbinNiz.push(this.user.belbinTest.OI);
    belbinNiz.push(this.user.belbinTest.OK);
    belbinNiz.push(this.user.belbinTest.S);
    belbinNiz.push(this.user.belbinTest.CT);
    belbinNiz.push(this.user.belbinTest.P);

    let max1 = 0;
    let maxIndex1 = 0;
    for (var x = 0; x < belbinNiz.length; x++) {
      if (belbinNiz[x] > max1) {
        max1 = belbinNiz[x];
        maxIndex1 = x;
      }
    }
    let max2 = 0;
    let maxIndex2 = 0;
    for (x = 0; x < belbinNiz.length; x++) {
      if (belbinNiz[x] > max2 && x != maxIndex1) {
        max2 = belbinNiz[x];
        maxIndex2 = x;
      }
    }
    let min = 1000;
    let minIndex = 0;
    for (x = 0; x < belbinNiz.length; x++) {
      if (belbinNiz[x] < min) {
        min = belbinNiz[x];
        minIndex = x;
      }
    }

    this.belbinNajvisiPrvi = maxIndex1;
    this.belbinNajvisiDrugi = maxIndex2;
    this.belbinNajnizi = minIndex;

    this.randomNumber = Math.floor(Math.random() * 127);
    this.N1 = this.user.testLicnosti.N1;
    this.N2 = this.user.testLicnosti.N2;
    this.N3 = this.user.testLicnosti.N3;
    this.N4 = this.user.testLicnosti.N4;
    this.N5 = this.user.testLicnosti.N5;
    this.N6 = this.user.testLicnosti.N6;

    this.E1 = this.user.testLicnosti.E1;
    this.E2 = this.user.testLicnosti.E2;
    this.E3 = this.user.testLicnosti.E3;
    this.E4 = this.user.testLicnosti.E4;
    this.E5 = this.user.testLicnosti.E5;
    this.E6 = this.user.testLicnosti.E6;

    this.O1 = this.user.testLicnosti.O1;
    this.O2 = this.user.testLicnosti.O2;
    this.O3 = this.user.testLicnosti.O3;
    this.O4 = this.user.testLicnosti.O4;
    this.O5 = this.user.testLicnosti.O5;
    this.O6 = this.user.testLicnosti.O6;

    this.U1 = this.user.testLicnosti.U1;
    this.U2 = this.user.testLicnosti.U2;
    this.U3 = this.user.testLicnosti.U3;
    this.U4 = this.user.testLicnosti.U4;
    this.U5 = this.user.testLicnosti.U5;
    this.U6 = this.user.testLicnosti.U6;

    this.S1 = this.user.testLicnosti.S1;
    this.S2 = this.user.testLicnosti.S2;
    this.S3 = this.user.testLicnosti.S3;
    this.S4 = this.user.testLicnosti.S4;
    this.S5 = this.user.testLicnosti.S5;
    this.S6 = this.user.testLicnosti.S6;

    this.chartData.NEUROTICIZAM =
      this.N1 + this.N2 + this.N3 + this.N4 + this.N5 + this.N6;
    this.chartData.EKSTRAVERZIJA =
      this.E1 + this.E2 + this.E3 + this.E4 + this.E5 + this.E6;
    this.chartData.OTVORENOST =
      this.O1 + this.O2 + this.O3 + this.O4 + this.O5 + this.O6;
    this.chartData.SARADLJIVOST =
      this.U1 + this.U2 + this.U3 + this.U4 + this.U5 + this.U6;
    this.chartData.SAVESNOST =
      this.S1 + this.S2 + this.S3 + this.S4 + this.S5 + this.S6;

    this.chartDataVq.A =
      (25 - this.user.vqTest.result) / 2 + this.user.vqTest.result;
    this.chartDataVq.B =
      25 - ((25 - this.user.vqTest.result) / 2 + this.user.vqTest.result);

    this.chartDataDetails.Anksioznost = this.user.testLicnosti.N1;
    this.chartDataDetails.Hostilnost = this.user.testLicnosti.N2;
    this.chartDataDetails.Depresivnost = this.user.testLicnosti.N3;
    this.chartDataDetails.Socijalna_nelagodnost = this.user.testLicnosti.N4;
    this.chartDataDetails.Impulzivnost = this.user.testLicnosti.N5;
    this.chartDataDetails.Vulnerabilnost = this.user.testLicnosti.N6;

    this.chartDataDetails.Toplina = this.user.testLicnosti.E1;
    this.chartDataDetails.Druželjubivost = this.user.testLicnosti.E2;
    this.chartDataDetails.Asertivnost = this.user.testLicnosti.E3;
    this.chartDataDetails.Aktivnost = this.user.testLicnosti.E4;
    this.chartDataDetails.Traženje_uzbuđenja = this.user.testLicnosti.E5;
    this.chartDataDetails.Pozitivne_emocije = this.user.testLicnosti.E6;

    this.chartDataDetails.Fantaziranje = this.user.testLicnosti.O1;
    this.chartDataDetails.Estetika = this.user.testLicnosti.O2;
    this.chartDataDetails.Osećanja = this.user.testLicnosti.O3;
    this.chartDataDetails.Akcija = this.user.testLicnosti.O4;
    this.chartDataDetails.Ideje = this.user.testLicnosti.O5;
    this.chartDataDetails.Vrijednosti = this.user.testLicnosti.O6;

    this.chartDataDetails.Povjerenje = this.user.testLicnosti.U1;
    this.chartDataDetails.Iskrenost = this.user.testLicnosti.U2;
    this.chartDataDetails.Altruizam = this.user.testLicnosti.U3;
    this.chartDataDetails.Popustljivost = this.user.testLicnosti.U4;
    this.chartDataDetails.Skromnost = this.user.testLicnosti.U5;
    this.chartDataDetails.Blaga_narav = this.user.testLicnosti.U6;

    this.chartDataDetails.Kompetentnost = this.user.testLicnosti.S1;
    this.chartDataDetails.Organiziranost = this.user.testLicnosti.S2;
    this.chartDataDetails.Odgovornost = this.user.testLicnosti.S3;
    this.chartDataDetails.Težnja_za_postignućem = this.user.testLicnosti.S4;
    this.chartDataDetails.Samodisciplina = this.user.testLicnosti.S5;
    this.chartDataDetails.Promišljenost = this.user.testLicnosti.S6;

    // Note: changes to the plugin code is not reflected to the chart, because the plugin is loaded at chart construction time and editor changes only trigger an chart.update().
    if (this.user.testLicnosti.finished) {
      this.dateExistsTestLicnosti();
    }
    if (this.user.vqTest.finished) {
      this.dateExistsVQ();
    }

    await this.getAllPsiholoskaProcena();
    //     procenaOpstaOcena
    // procenaPozitivno
    // procenaNegativno
    // procenaMisljenjePsihologa
    for (i = 0; i < this.psiholoskaProcenas.length; i++) {
      if (this.psiholoskaProcenas[i].idUser == this.user._id) {
        this.procenaOpstaOcena = this.psiholoskaProcenas[i].opstaOcena;
        this.procenaPozitivno = this.psiholoskaProcenas[i].pozitivno;
        this.procenaNegativno = this.psiholoskaProcenas[i].negativno;
        this.procenaMisljenjePsihologa = this.psiholoskaProcenas[
          i
        ].misljenjePsihologa;

        if (this.psiholoskaProcenas[i].savedVq) {
          this.vqHeader = this.psiholoskaProcenas[i].vqHeader;
          this.vqBody = this.psiholoskaProcenas[i].vqBody;
          this.savedVq = this.psiholoskaProcenas[i].savedVq;
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "submitPsiholoskaProcena",
      "getAllPsiholoskaProcena",
      "addPerformanceEval",
      "getCollegues",
    ]),

    async addPerformanceEvaluationForSelectedUser() {
      let kpiData = {
        userId: this.user._id,
        companyKpiConnector: this.companyConnector,
      };
      await this.addPerformanceEval(kpiData);
      this.evalEmpty = false;
      this.$emit("addPerEval", true);
    },

    async loadKpi(index) {
      ///////////KPI NOT EMPTY
      if (!this.evalEmpty) {
        this.konacnaProcena = this.user.performanceEvaluation[index].komentar;
        this.samoProcena = this.user.performanceEvaluation[
          index
        ].komentarSamoProcena;
        if (this.user.performanceEvaluation[index].companyKpiConnector) {
          this.companyKpiConnector = this.user.performanceEvaluation[
            index
          ].companyKpiConnector;
        }

        this.kompetence = [];
        this.kompanijskiKpi = [];
        this.licniKpi = [];

        for (
          i = 0;
          i < this.user.performanceEvaluation[index].kompetence.length;
          i++
        ) {
          let emptyObj = {};
          this.kompetence.push(emptyObj);
          this.kompetence[i].samoProcena = this.user.performanceEvaluation[
            index
          ].kompetence[i].samoProcena;
          this.kompetence[i].konacnaProcena = this.user.performanceEvaluation[
            index
          ].kompetence[i].konacnaProcena;
          this.kompetence[i].rangiranje = this.user.performanceEvaluation[
            index
          ].kompetence[i].rangiranje;
          this.kompetence[i].name = this.user.performanceEvaluation[
            index
          ].kompetence[i].name;
          this.kompetence[i].ponder = this.user.performanceEvaluation[
            index
          ].kompetence[i].ponder;
        }
        for (
          i = 0;
          i < this.user.performanceEvaluation[index].kompanijski.length;
          i++
        ) {
          let emptyObj = {};
          this.kompanijskiKpi.push(emptyObj);
          this.kompanijskiKpi[i].samoProcena = this.user.performanceEvaluation[
            index
          ].kompanijski[i].samoProcena;
          this.kompanijskiKpi[
            i
          ].konacnaProcena = this.user.performanceEvaluation[index].kompanijski[
            i
          ].konacnaProcena;
          this.kompanijskiKpi[i].rangiranje = this.user.performanceEvaluation[
            index
          ].kompanijski[i].rangiranje;
          this.kompanijskiKpi[i].name = this.user.performanceEvaluation[
            index
          ].kompanijski[i].name;
          this.kompanijskiKpi[i].ponder = this.user.performanceEvaluation[
            index
          ].kompanijski[i].ponder;
        }

        for (
          i = 0;
          i < this.user.performanceEvaluation[index].licniKpi.length;
          i++
        ) {
          this.licniKpi.push(
            this.user.performanceEvaluation[index].licniKpi[i]
          );
        }
        if (this.user.performanceEvaluation[index].licniKpiTitle != undefined) {
          this.licniKpiTitle = this.user.performanceEvaluation[
            index
          ].licniKpiTitle;
        } else {
          this.licniKpiTitle = "Licni KPI";
        }
        // for ( i = 0; i < this.user.performanceEvaluation.length; i++) {
        //   this.date.push(this.user.performanceEvaluation[i].date);
        // }

        let tempCompany = {};
        for (var i = 0; i < this.companys.length; i++) {
          if (this.companys[i].name == this.user.type) {
            tempCompany = this.companys[i];
          }
        }
        this.kompetenceBool = [];
        this.kompanijskiBool = [];
        this.licniBool = [];

        let brojac = this.user.performanceEvaluation[index].kompetence.length;

        for (i = 0; i < brojac; i++) {
          if (
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompetence[i]
          ) {
            let komp = {
              bool: false,
              comment:
                tempCompany.performanceEvaluation[this.companyKpiConnector]
                  .kompetence[i].comment,
            };
            this.kompetenceBool.push(komp);
          }
        }

        brojac = this.user.performanceEvaluation[index].kompanijski.length;
        for (i = 0; i < brojac; i++) {
          let komp = {
            bool: false,
            comment:
              tempCompany.performanceEvaluation[this.companyKpiConnector]
                .kompanijski[i].comment,
          };
          this.kompanijskiBool.push(komp);
        }
        brojac = this.user.performanceEvaluation[index].licniKpi.length;
        for (i = 0; i < brojac; i++) {
          let komp = {
            bool: false,
            comment: this.user.performanceEvaluation[index].licniKpi[i].comment,
          };
          this.licniBool.push(komp);
        }

        //kompetence
        let ukupnoKompetence = 0;
        for (
          i = 0;
          i < this.user.performanceEvaluation[index].kompetence.length;
          i++
        ) {
          if (
            this.user.performanceEvaluation[index].kompetence[i]
              .konacnaProcena != null
          ) {
            ukupnoKompetence += parseFloat(
              this.user.performanceEvaluation[index].kompetence[
                i
              ].rangiranje.toFixed(2)
            );
          }
        }
        this.kompetenceOcena = ukupnoKompetence;

        //licni
        let ukupnoLicni = 0;
        for (
          i = 0;
          i < this.user.performanceEvaluation[index].licniKpi.length;
          i++
        ) {
          if (
            this.user.performanceEvaluation[index].licniKpi[i].konacnaProcena !=
            null
          ) {
            ukupnoLicni += parseFloat(
              this.user.performanceEvaluation[index].licniKpi[
                i
              ].rangiranje.toFixed(2)
            );
          }
        }
        this.licniOcena = ukupnoLicni;
        //kompanijski
        let ukupnoKompanijski = 0;
        for (
          i = 0;
          i < this.user.performanceEvaluation[index].kompanijski.length;
          i++
        ) {
          if (
            this.user.performanceEvaluation[index].kompanijski[i]
              .konacnaProcena != null
          ) {
            ukupnoKompanijski += parseFloat(
              this.user.performanceEvaluation[index].kompanijski[
                i
              ].rangiranje.toFixed(2)
            );
          }
        }
        this.kompanijskiOcena = ukupnoKompanijski;

        this.ukupnoKpi =
          (tempCompany.performanceEvaluation[this.companyKpiConnector]
            .kompetenceProcenat /
            100) *
            ukupnoKompetence +
          (tempCompany.performanceEvaluation[this.companyKpiConnector]
            .licniProcenat /
            100) *
            ukupnoLicni +
          (tempCompany.performanceEvaluation[this.companyKpiConnector]
            .kompanijskiProcenat /
            100) *
            ukupnoKompanijski;
        this.kompetenceProc =
          tempCompany.performanceEvaluation[
            this.companyKpiConnector
          ].kompetenceProcenat;
        this.licniProc =
          tempCompany.performanceEvaluation[
            this.companyKpiConnector
          ].licniProcenat;
        this.kompanijskiProc =
          tempCompany.performanceEvaluation[
            this.companyKpiConnector
          ].kompanijskiProcenat;
      }
      ///////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////
    },
    ukloniPozitivno(i) {
      this.procenaPozitivno.splice(i, 1);
    },
    increasePozitivno() {
      let emptyStr = "";
      this.procenaPozitivno.push(emptyStr);
    },
    ukloniNegativno(i) {
      this.procenaNegativno.splice(i, 1);
    },
    increaseNegativno() {
      let emptyStr = "";
      this.procenaNegativno.push(emptyStr);
    },

    async submitProcena() {
      let data = {
        opstaOcena: this.procenaOpstaOcena,
        pozitivno: this.procenaPozitivno,
        negativno: this.procenaNegativno,
        misljenjePsihologa: this.procenaMisljenjePsihologa,
        idUser: this.user._id,
        vqHeader: this.vqHeader,
        vqBody: this.vqBody,
        savedVq: this.savedVq,
      };

      await this.submitPsiholoskaProcena(data);
      this.validationPsih = "Sacuvana Psiholoska Procena.";
    },

    onChangeDate(event) {
      this.kompetence = [];
      this.kompanijskiKpi = [];
      this.licniKpi = [];

      for (var i = 0; i < this.user.performanceEvaluation.length; i++) {
        if (event.target.value == this.user.performanceEvaluation[i].date) {
          this.lastEval = i;
        }
      }
      if (this.user.performanceEvaluation[this.lastEval].companyKpiConnector) {
        this.companyKpiConnector = this.user.performanceEvaluation[
          this.lastEval
        ].companyKpiConnector;
      }

      this.loadKpi(this.lastEval);
      //     this.konacnaProcena=this.user.performanceEvaluation[this.lastEval].komentar;
      //     this.samoProcena=this.user.performanceEvaluation[this.lastEval].komentarSamoProcena;
      //
      //
      //
      //
      //     let tempCompany={};
      //     for ( i = 0; i < this.companys.length; i++) {
      //       if(this.companys[i].name == this.user.type){
      //         tempCompany=this.companys[i];
      //       }
      //     }
      //
      //     //kompetence
      //     let ukupnoKompetence = 0;
      //     for ( i = 0; i < this.user.performanceEvaluation[this.lastEval].kompetence.length; i++) {
      //       if(this.user.performanceEvaluation[this.lastEval].kompetence[i].konacnaProcena!=null){
      //         ukupnoKompetence+=this.user.performanceEvaluation[this.lastEval].kompetence[i].rangiranje;
      //       }
      //     }
      //     this.kompetenceOcena= ukupnoKompetence;
      //
      //     //licni
      //     let ukupnoLicni=0;
      //     for ( i = 0; i < this.user.performanceEvaluation[this.lastEval].licniKpi.length; i++) {
      //       if(this.user.performanceEvaluation[this.lastEval].licniKpi[i].konacnaProcena!=null){
      //         ukupnoLicni+=this.user.performanceEvaluation[this.lastEval].licniKpi[i].rangiranje;
      //       }
      //     }
      //     this.licniOcena=ukupnoLicni;
      //     //kompanijski
      //     let ukupnoKompanijski=0;
      //     for ( i = 0; i < this.user.performanceEvaluation[this.lastEval].kompanijski.length; i++) {
      //       if(this.user.performanceEvaluation[this.lastEval].kompanijski[i].konacnaProcena!=null){
      //         ukupnoKompanijski+= this.user.performanceEvaluation[this.lastEval].kompanijski[i].rangiranje;
      //       }
      //     }
      //     this.kompanijskiOcena=ukupnoKompanijski;
      //
      //
      //
      //     this.ukupnoKpi = tempCompany.performanceEvaluation.kompetenceProcenat/100 * ukupnoKompetence + tempCompany.performanceEvaluation.licniProcenat/100 * ukupnoLicni + tempCompany.performanceEvaluation.kompanijskiProcenat/100*ukupnoKompanijski;
      //
      //     this.kompetenceProc=tempCompany.performanceEvaluation.kompetenceProcenat;
      //     this.licniProc=tempCompany.performanceEvaluation.licniProcenat;
      //     this.kompanijskiProc=tempCompany.performanceEvaluation.kompanijskiProcenat;
      //
      //
      //
      //
      // for ( i = 0; i < this.user.performanceEvaluation[this.lastEval].kompetence.length; i++) {
      //   let emptyObj={};
      //   this.kompetence.push(emptyObj);
      //   this.kompetence[i].samoProcena=this.user.performanceEvaluation[this.lastEval].kompetence[i].samoProcena;
      //   this.kompetence[i].konacnaProcena=this.user.performanceEvaluation[this.lastEval].kompetence[i].konacnaProcena;
      //   this.kompetence[i].rangiranje=this.user.performanceEvaluation[this.lastEval].kompetence[i].rangiranje;
      //   this.kompetence[i].name=this.user.performanceEvaluation[this.lastEval].kompetence[i].name;
      //   this.kompetence[i].ponder=this.user.performanceEvaluation[this.lastEval].kompetence[i].ponder;
      // }
      // for ( i = 0; i < this.user.performanceEvaluation[this.lastEval].kompanijski.length; i++) {
      //   let emptyObj={};
      //   this.kompanijskiKpi.push(emptyObj);
      //   this.kompanijskiKpi[i].samoProcena=this.user.performanceEvaluation[this.lastEval].kompanijski[i].samoProcena;
      //   this.kompanijskiKpi[i].konacnaProcena=this.user.performanceEvaluation[this.lastEval].kompanijski[i].konacnaProcena;
      //   this.kompanijskiKpi[i].rangiranje=this.user.performanceEvaluation[this.lastEval].kompanijski[i].rangiranje;
      //     this.kompanijskiKpi[i].name=this.user.performanceEvaluation[this.lastEval].kompanijski[i].name;
      //     this.kompanijskiKpi[i].ponder=this.user.performanceEvaluation[this.lastEval].kompanijski[i].ponder;
      // }
      // this.licniKpi=[];
      // for ( i = 0; i < this.user.performanceEvaluation[this.lastEval].licniKpi.length; i++) {
      //         this.licniKpi.push(this.user.performanceEvaluation[this.lastEval].licniKpi[i]);
      // }
      // //this.updateTable();
      //
      //
      // this.kompetenceBool=[];
      // this.kompanijskiBool=[];
      // this.licniBool=[];
      //
      // let brojac = this.user.performanceEvaluation[this.lastEval].kompetence.length;
      // for ( i = 0; i < brojac; i++) {
      //   let komp={
      //     bool:false,
      //     comment:tempCompany.performanceEvaluation.kompetence[i].comment
      //   }
      //   this.kompetenceBool.push(komp);
      //
      // }
      // brojac = this.user.performanceEvaluation[this.lastEval].kompanijski.length;
      // for ( i = 0; i < brojac; i++) {
      //   let komp={
      //     bool:false,
      //     comment:tempCompany.performanceEvaluation.kompanijski[i].comment
      //   }
      //   this.kompanijskiBool.push(komp);
      // }
      // brojac = this.user.performanceEvaluation[this.lastEval].licniKpi.length;
      // for ( i = 0; i < brojac; i++) {
      //   let komp={
      //     bool:false,
      //     comment:this.user.performanceEvaluation[this.lastEval].licniKpi[i].comment
      //   }
      //   this.licniBool.push(komp);
      // }
      //
      //
    },

    swapBoolKompetence(i) {
      if (this.kompetenceBool[i].bool) {
        this.kompetenceBool[i].bool = false;
      } else {
        this.kompetenceBool[i].bool = true;
      }
    },
    swapBoolKompanijski(i) {
      if (this.kompanijskiBool[i].bool) {
        this.kompanijskiBool[i].bool = false;
      } else {
        this.kompanijskiBool[i].bool = true;
      }
    },
    swapBoolLicni(i) {
      if (this.licniBool[i].bool) {
        this.licniBool[i].bool = false;
      } else {
        this.licniBool[i].bool = true;
      }
    },
    async makePDFVQ() {
      var downloading = document.getElementById("vqTest");
      var doc = new jsPDF("l", "pt", "a4");

      await html2canvas(downloading, {
        scale: 2,
        allowTaint: true,
        useCORS: true,
        width: 810,
        height: 560,
      }).then((canvas) => {
        doc.addImage(canvas.toDataURL("image/png"), "PNG", 5, 5, 810, 560);
      });

      doc.save("Document.pdf");
    },

    makePDF360() {
      window.html2canvas = html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("l", "pt", "a4");
      doc.html(document.querySelector("#feedbackPDF"), {
        callback: function(pdf) {
          pdf.save("mypdf.pdf");
        },
      });
    },
    makePDFKPI() {
      this.letterSpacing = true;
      setTimeout(() => {
        this.letterSpacing = false;
      }, 2000);
      window.html2canvas = html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("p", "pt", "a4");
      doc.setFont("Merriweather-Regular", "normal");
      doc.html(document.querySelector("#kpi"), {
        html2canvas: {
          scale: 0.45,
          letterRendering: true,
          //scrollY:0
        },
        callback: function(pdf) {
          pdf.save("mypdf.pdf");
        },
        margin: [15, 15],
      });
    },
    makePDFBelvin() {
      window.html2canvas = html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("l", "pt", "a4");
      doc.html(document.querySelector("#belvin"), {
        callback: function(pdf) {
          pdf.save("mypdf.pdf");
        },
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    dateExistsVQ() {
      let str = this.user.vqTest.date.substring(0, 19);
      this.vqDate =
        "Datum: " +
        str.substring(0, 4) +
        "." +
        str.substring(5, 7) +
        "." +
        str.substring(8, 10) +
        ". Vreme:" +
        str.substring(11, 19);
    },
    dateExistsTestLicnosti() {
      let str = this.user.testLicnosti.date.substring(0, 19);
      this.testLicnostiDate =
        "Datum: " +
        str.substring(0, 4) +
        "." +
        str.substring(5, 7) +
        "." +
        str.substring(8, 10) +
        ". Vreme:" +
        str.substring(11, 19);
    },
  },
};
</script>

<style lang="css" scoped>
#vqTest {
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding: 15px;
  width: 810px;
  height: 560px;
  background: linear-gradient(110deg, #77bbff 60%, #99ddff 60%);
  margin: auto;
}

.testLicnosti {
}

#detailedChart {
  background-image: url("~@/assets/graph2.png");
  background-size: cover;
}

#chart {
  background-image: url("~@/assets/graph1.png");
  background-size: cover;
}

table {
  border: 2px solid black;
  text-align: center;
}

td,
th {
  text-align: center;
  border: 1px solid black;
}

tr:nth-child(even) {
  background-color: #aaaacc;
}

tr:nth-child(odd) {
  background-color: #ccccff;
}

tr:hover {
  background-color: #afa;
}

.stazH1 {
  grid-area: stazH1;
}

.stazTable {
  grid-area: stazTable;
}

.stazGraph {
  grid-area: stazGraph;
}

.demografija {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
}

#svaPitanja1 {
  width: 810px;
  margin: 15px;
  padding: 15px;
}

#svaPitanja2 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 15px;
}

#svaPitanja3 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
}

#svaPitanja4 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
}

#svaPitanja5 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 15px;
}

#svaPitanja6 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
}

#svaPitanja7 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 20px;
}

#svaPitanja8 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 60px;
}

#svaPitanja9 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 25px;
}

#svaPitanja10 {
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
}

.podaci {
  background-color: #cc7777;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;

  margin-top: 15px;
  text-align: center;
}

h1 {
  text-align: center;
}

.header {
  text-align: center;
  background-color: #ffaaaa;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin-top: 15px;
  background: linear-gradient(110deg, #77bbff 60%, #99ddff 60%);
}

.telo {
  margin: auto;
}

#tableHead {
  margin: auto;
}

.evaluation1 {
  color: black;
  background-color: red;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 15px;
  width: 800px;
}

.evaluation2 {
  color: black;
  background-color: yellow;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 15px;
  width: 800pxevaluation4;
}

.evaluation3 {
  color: black;
  background-color: cyan;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 15px;
  width: 800px;
}

.evaluation4 {
  color: black;
  background-color: green;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 15px;
  width: 800px;
}

#belvinTable {
  margin: auto;
}

#belvin {
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding: 15px;
  width: 840px;
  height: 590px;

  margin: auto;
}

#kpi {
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding: 15px;

  text-align: center;
  margin: auto;
}

#licniTable {
  margin: auto;
}

#kompanijskiTable {
  margin: auto;
}

#kompetenceTable {
  margin: auto;
}

#feedBackHeader {
  background-color: #ccccff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  margin: 15px;
  padding: 15px;
  width: 810px;
  height: 570px;
  /* background: linear-gradient(110deg, #aaaacc 60%, #ccccff 60%); */
  text-align: center;
}

#feedbackPDF {
  margin: auto;
}

.marginTop80px {
  margin-top: 80px;
}

#jedan {
  background-color: red;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

#dva {
  background-color: yellow;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

#tri {
  background-color: cyan;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

#cetiri {
  background-color: green;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

#komentarEval {
  background-color: white;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 5px;
}

.definicija {
  background-color: #ffff88;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  float: right;
}

.definicija:hover {
  background-color: #dddd66;
}

.procenaElement {
  border-style: solid;
  border-color: black;
  border-width: thin;

  margin: 10px;
  padding: 10px;
}

.letterSpacing {
  letter-spacing: 2px;
}
@media print {
  body * {
    visibility: hidden;
    overflow: hidden;
  }
  #kpi,
  #kpi *,
  .podaci,
  .podaci * {
    visibility: visible;
  }
  .podaci {
    position: absolute;
    left: 0;
    top: 0;
    width: 800px;
  }
  #kpi {
    position: absolute;
    left: 0;
    top: 130px;
    width: 800px;
  }
}
.kompetence-wrapper {
  overflow: auto;
}
</style>
