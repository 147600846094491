<template lang="html">

  <div id="pdf">


    <table id="table">
      <tr>
        <th>Zaposleni</th>
        <th>Podredjeni</th>
        <th>Nadredjeni</th>
        <th>Isti Nivo</th>
        <th>Ukupno</th>
      </tr>

      <tr v-for="(user,i) in usersSameCompany" :key="i">
        <td>{{user.name}}</td>
        <td>{{user.feedBackTest.prosek.podredjeni.toFixed(2)}}</td>
        <td>{{user.feedBackTest.prosek.nadredjeni.toFixed(2)}}</td>
        <td>{{user.feedBackTest.prosek.istiNivo.toFixed(2)}}</td>
        <td>{{user.feedBackTest.prosek.ukupno.toFixed(2)}}</td>
      </tr>

      <tr>
        <td colspan="4">Prosek Svi</td>
        <td></td>
      </tr>

    </table>

  </div>
  <button @click="makePDF">MakePDF</button>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';

export default {
  props:{
    company:{
      type: Object
    }
  },
computed: mapGetters(['usersSameCompany']),
  data(){
    return{
      collaguesNumber:0,
      nadredjeniUkupno:0,
      podredjeniUkupno:0,
      istiUkupno:0,
    }
  },
  methods:{
    ...mapActions(['getCollegues']),
    makePDF(){
      window.html2canvas=html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("l","pt","a4");
      doc.html(document.querySelector("#pdf"),{
        callback: function(pdf){
          pdf.save("mypdf.pdf");
        }
      });
    },
  },
  async created(){
    await this.getCollegues(this.company.name);

  }
}
</script>

<style lang="css" scoped>
#pdf{
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: small;
  margin: 20px;
  padding: 15px;
  width: 810px;
  height: 573px;
  text-align: center;
}

table{
  border: 2px solid black;
  text-align: center;
  margin:auto;
}
td, th{
  border: 1px solid black;
  text-align: center;
}
tr:nth-child(even){background-color: #aaaacc;}
tr:nth-child(odd){background-color: #ccccff;}
tr:hover {background-color: #afa;}
</style>
