<template lang="html">
  <div v-if="tabType" class="custom-dropdown bg-dark" @click="toggleDropdown">
    <div class="selected-option ">{{ selected || "Izaberite kompaniju" }}</div>
    <div class="dropdown-list" v-if="showDropdown" @click="toggleDropdown">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        class="dropdown-option bg-dark "
        @click="setTab(tab)"
      >
        {{ tab }}
      </div>
    </div>
    <!-- <img style="fill: red;" src="../assets/icons/angle-down.svg" /> -->
  </div>

  <div class="tabNav">
    <ul class="nav nav-tabs">
      <li v-for="(tab, i) in tabs" :key="tab" class="nav-item">
        <div class="navbar-brand">
          <!-- Activate this if you do not want <select>  -->
          <!-- <a
            v-if="tabType"
            class="tab-link-main"
            :class="{ active: tab === selected }"
            @click="setTab(tab)"
          >
            {{ tab }}
          </a> -->

          <a
            v-if="!tabType && i >= 4"
            class="tab_link_employee"
            :class="{ active: tab === selected }"
            @click="setTab(tab)"
          >
            {{ tab }}
            <span v-if="!selfEvaluation[i - 4]" class="text-warning fw-bold"
              >S </span
            ><span v-if="!konacnaProcena[i - 4]" class="text-warning fw-bold"
              >K</span
            >
          </a>

          <a
            v-if="!tabType && i < 4"
            class="tab_link_report"
            :class="{ active: tab === selected }"
            @click="setTab(tab)"
          >
            {{ tab }}
          </a>
        </div>
      </li>
    </ul>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    tabType: {
      type: Boolean,
    },
    selfEvaluation: {
      type: Array,
    },
    konacnaProcena: {
      type: Array,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    setTab(tab) {
      this.$emit("selected", tab);
      console.log(tab);
      this.showDropdown = !this.showDropdown;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style lang="css" scoped>
.custom-dropdown {
  position: relative;
  display: flex;
  cursor: pointer;
  width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: space-between;
  color: white;

  img {
    width: 15px;
  }
}

.dropdown-list {
  position: absolute;
  margin-top: 1px;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: block;
  height: 300px;
  overflow-y: auto;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
  color: white;
}

.dropdown-option:hover {
  background-color: #f0f0f0 !important;
  color: black;
}

.navbar-brand {
  margin: 1px;
  padding: 0;
}

.nav-tabs {
  padding-bottom: 10px;
}

.tabNav {
  padding-top: 15px;
}

.tab_link_employeee {
  border-color: #000410;
  border: 10px;
  text-align: center;
  padding: 5px 15px;
  background-color: #007030;
  color: #ffffff;
  text-decoration: none;
  border-style: solid;
  border-color: black;
  border-width: medium;
}

.tab_link_reports {
  border-color: #000410;
  border: 10px;
  text-align: center;
  padding: 5px 15px;
  background-color: #700030;
  color: #ffffff;
  text-decoration: none;
  border-style: solid;
  border-color: black;
  border-width: medium;
  cursor: pointer;
}

.tab_link_employee:hover {
  background-color: #000410;
  cursor: pointer;
}

.tab_link_employee:visited {
  background-color: #000033;
}

.tab-link-main {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 10px 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

.tab-link-main:before,
.tab-link-main:after {
  border-radius: 0px;
}

.tab-link-main:before {
  background-image: linear-gradient(92.83deg, #030202 0, #0e0402 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.tab-link-main:after {
  background-color: initial;
  background-image: linear-gradient(#6754aa 0, #003070 100%);
  bottom: 3px;
  content: "";
  display: block;
  left: 3px;
  overflow: hidden;
  position: absolute;
  right: 3px;
  top: 3px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.tab-link-main:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(80, 79, 78) 0%, rgb(3, 3, 3) 100%);
}

.tab-link-main:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.tab-link-main:active:not(:disabled) {
  color: #ccc;
}

.tab-link-main:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.tab-link-main:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.tab-link-main:disabled {
  cursor: default;
  opacity: 0.24;
}

.tab_link_report {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 10px 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

.tab_link_report:before,
.tab_link_report:after {
  border-radius: 0px;
}

.tab_link_report:before {
  background-image: linear-gradient(92.83deg, #030202 0, #0e0402 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.tab_link_report:after {
  background-color: initial;
  background-image: linear-gradient(#aa1818 0, #77050a 100%);
  bottom: 3px;
  content: "";
  display: block;
  left: 3px;
  overflow: hidden;
  position: absolute;
  right: 3px;
  top: 3px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.tab_link_report:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(80, 79, 78) 0%, rgb(3, 3, 3) 100%);
}

.tab_link_report:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.tab_link_report:active:not(:disabled) {
  color: #ccc;
}

.tab_link_report:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.tab_link_report:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.tab_link_report:disabled {
  cursor: default;
  opacity: 0.24;
}

.tab_link_employee {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 10px 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

.tab_link_employee:before,
.tab_link_employee:after {
  border-radius: 0px;
}

.tab_link_employee:before {
  background-image: linear-gradient(92.83deg, #030202 0, #0e0402 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.tab_link_employee:after {
  background-color: initial;
  background-image: linear-gradient(#2a850f 0, #045a17 100%);
  bottom: 3px;
  content: "";
  display: block;
  left: 3px;
  overflow: hidden;
  position: absolute;
  right: 3px;
  top: 3px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.tab_link_employee:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(80, 79, 78) 0%, rgb(3, 3, 3) 100%);
}

.tab_link_employee:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.tab_link_employee:active:not(:disabled) {
  color: #ccc;
}

.tab_link_employee:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.tab_link_employee:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.tab_link_employee:disabled {
  cursor: default;
  opacity: 0.24;
}

@media print {
  body * {
    visibility: hidden;
    overflow: hidden;
  }
}
</style>
