<template lang="html">
  <div class="">
    <h1>Rezultati</h1>
  </div>

  <div class="overlay">
    <TabNav
      :tabs="companyList"
      :selected="selected"
      :tabType="true"
      @selected="setSelected"
      id="companyNav"
    >
      <Tab
        v-for="(company, i) in companyList"
        :key="company"
        :isSelected="selected === company"
      >
        <div v-if="loading" class="loader"></div>

        <div class="mt-2 d-flex align-items-center">
          <label class="fw-bold me-2">Pretraga zaposlenih: </label>
          <input @input="filterUser()" v-model="search" />
          <button
            class="btn btn-danger border border-dark py-0 ms-2"
            data-toggle="tooltip"
            data-placement="top"
            title="Refresh Search"
            @click="deleteFilter()"
          >
            X
          </button>
        </div>
        <div v-if="!loading">
          <TabNav
            :tabs="listOfListsOfUserNames[i]"
            :selfEvaluation="selfEvaluation[i]"
            :konacnaProcena="konacnaProcena[i]"
            :selected="selected2"
            :tabType="false"
            @selected="setSelected2"
          >
            <Tab
              :isSelected="selected2 === 'StavoviZaposlenih'"
              class="greenTab"
            >
              <StavoviZaposlenihComponent :company="companys[i + 1]">
              </StavoviZaposlenihComponent>
            </Tab>
            <Tab :isSelected="selected2 === 'FeedBack'">
              <FeedBackCompanyComponent :company="companys[i + 1]">
              </FeedBackCompanyComponent>
            </Tab>

            <Tab :isSelected="selected2 === 'Kpi'">
              <CompanyEvaluation :company="companys[i + 1]">
              </CompanyEvaluation>
            </Tab>

            <Tab :isSelected="selected2 === 'ProcenaEdukacije'">
              <ProcenaEdukacijeIzvestaj :company="companys[i + 1]">
              </ProcenaEdukacijeIzvestaj>
            </Tab>

            <Tab
              v-for="userFromList in listOfListsOfUsers[i]"
              :key="userFromList._id"
              :isSelected="selected2 === userFromList.name"
            >
              <UserTestComponent
                :selectedCompanyCustomProcena="companys[i + 1].customProcena"
                :user="userFromList"
                :companyConnector="currentCompanyActiveConnector"
                @addPerEval="refreshUser()"
              />
            </Tab>
          </TabNav>
        </div>
      </Tab>
    </TabNav>
  </div>
</template>

<script type="text/javascript">
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import TabNav from "@/components/TabNav.vue";
import Tab from "@/components/Tab.vue";
import UserTestComponent from "@/components/UserTestsComponent.vue";
import StavoviZaposlenihComponent from "@/components/StavoviZaposlenihComponent.vue";
import FeedBackCompanyComponent from "@/components/FeedBackCompanyComponent.vue";
import CompanyEvaluation from "@/components/CompanyEvaluation.vue";
import ProcenaEdukacijeIzvestaj from "@/components/ProcenaEdukacijeIzvestaj.vue";

export default {
  computed: mapGetters([
    "vqtests",
    "companys",
    "testLicnostis",
    "stavoviZaposlenihs",
    "feedbackTests",
    "users",
    "user",
    "usersSameCompany",
  ]),
  components: {
    TabNav,
    Tab,
    UserTestComponent,
    StavoviZaposlenihComponent,
    FeedBackCompanyComponent,
    CompanyEvaluation,
    ProcenaEdukacijeIzvestaj,
  },
  data() {
    return {
      selected: "",
      selected2: "",
      companyList: [],
      //each element is a list of users (different lists are for different companies)
      listOfListsOfUsers: [],
      listOfListsOfUserNames: [],
      currentCompanyName: "",
      currentCompanyActiveConnector: 0,
      loading: true,
      search: "",
      filteredUsers: [],
      iMemory: null,
      selfEvaluation: [],
      konacnaProcena: [],
    };
  },
  methods: {
    ...mapActions([
      "getVQTests",
      "getFeedBackTests",
      "getStavoviZaposlenih",
      "getTestLicnosti",
      "getCompanies",
      "getAllUsers",
      "getProfile",
      "getCollegues",
    ]),
    async refreshUser() {
      await this.getCollegues(this.currentCompanyName);
      this.listOfListsOfUsers[this.iMemory] = [];
      this.selfEvaluation[this.iMemory] = [];
      this.konacnaProcena[this.iMemory] = [];
      this.listOfListsOfUserNames[this.iMemory] = [];
      this.listOfListsOfUserNames[this.iMemory].push("StavoviZaposlenih");
      this.listOfListsOfUserNames[this.iMemory].push("FeedBack");
      this.listOfListsOfUserNames[this.iMemory].push("Kpi");
      this.listOfListsOfUserNames[this.iMemory].push("ProcenaEdukacije");
      for (var i = 0; i < this.usersSameCompany.length; i++) {
        this.listOfListsOfUsers[this.iMemory].push(this.usersSameCompany[i]);
        let stringname = this.usersSameCompany[i].name;
        this.listOfListsOfUserNames[this.iMemory].push(stringname);
        let performanceArray = this.usersSameCompany[i].performanceEvaluation
          .length;
        if (performanceArray > 0) {
          let selfEval = this.usersSameCompany[i].performanceEvaluation[
            performanceArray - 1
          ].finished;
          this.selfEvaluation[this.iMemory].push(selfEval);
          if (
            this.usersSameCompany[i].performanceEvaluation[performanceArray - 1]
              .kompanijski[0]?.rangiranje > 0
          ) {
            this.konacnaProcena[this.iMemory].push(true);
          } else {
            this.konacnaProcena[this.iMemory].push(false);
          }
        }
      }
    },
    async setSelected(tab) {
      this.loading = true;
      this.selected = tab;
      for (var i = 0; i < this.companyList.length; i++) {
        if (this.companyList[i] == this.selected) {
          this.currentCompanyName = this.companyList[i];
          this.iMemory = i;
        }
      }
      for (i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == this.selected) {
          this.currentCompanyActiveConnector =
            this.companys[i].performanceEvaluation.length - 1;
        }
      }
      await this.getCollegues(this.currentCompanyName);
      this.listOfListsOfUsers[this.iMemory] = [];
      this.selfEvaluation[this.iMemory] = [];
      this.konacnaProcena[this.iMemory] = [];
      this.listOfListsOfUserNames[this.iMemory] = [];
      this.listOfListsOfUserNames[this.iMemory].push("StavoviZaposlenih");
      this.listOfListsOfUserNames[this.iMemory].push("FeedBack");
      this.listOfListsOfUserNames[this.iMemory].push("Kpi");
      this.listOfListsOfUserNames[this.iMemory].push("ProcenaEdukacije");
      for (i = 0; i < this.usersSameCompany.length; i++) {
        this.listOfListsOfUsers[this.iMemory].push(this.usersSameCompany[i]);
        let stringname = this.usersSameCompany[i].name;
        this.listOfListsOfUserNames[this.iMemory].push(stringname);
        let performanceArray = this.usersSameCompany[i].performanceEvaluation
          .length;
        if (performanceArray > 0) {
          let selfEval = this.usersSameCompany[i].performanceEvaluation[
            performanceArray - 1
          ].finished;
          this.selfEvaluation[this.iMemory].push(selfEval);
          if (
            this.usersSameCompany[i].performanceEvaluation[performanceArray - 1]
              .kompanijski[0]?.rangiranje > 0 ||
            this.usersSameCompany[i].performanceEvaluation[performanceArray - 1]
              .kompetence[0]?.rangiranje > 0 ||
            this.usersSameCompany[i].performanceEvaluation[performanceArray - 1]
              .komentar
          ) {
            this.konacnaProcena[this.iMemory].push(true);
          } else {
            this.konacnaProcena[this.iMemory].push(false);
          }
        }
      }
      this.loading = false;
    },
    setSelected2(tab) {
      this.selected2 = tab;
    },
    filterUser() {
      if (this.search) {
        this.listOfListsOfUserNames[this.iMemory] = [];
        this.listOfListsOfUserNames[this.iMemory].push("StavoviZaposlenih");
        this.listOfListsOfUserNames[this.iMemory].push("FeedBack");
        this.listOfListsOfUserNames[this.iMemory].push("Kpi");
        this.listOfListsOfUserNames[this.iMemory].push("ProcenaEdukacije");
        this.usersSameCompany.forEach((user) => {
          if (user.name.toLowerCase().includes(this.search.toLowerCase())) {
            this.listOfListsOfUserNames[this.iMemory].push(user.name);
          }
        });
      } else {
        this.listOfListsOfUserNames[this.iMemory] = [];
        this.listOfListsOfUserNames[this.iMemory].push("StavoviZaposlenih");
        this.listOfListsOfUserNames[this.iMemory].push("FeedBack");
        this.listOfListsOfUserNames[this.iMemory].push("Kpi");
        this.listOfListsOfUserNames[this.iMemory].push("ProcenaEdukacije");
        for (let i = 0; i < this.usersSameCompany.length; i++) {
          let stringname = this.usersSameCompany[i].name;
          this.listOfListsOfUserNames[this.iMemory].push(stringname);
        }
      }
    },
    deleteFilter() {
      this.search = "";
      this.listOfListsOfUserNames[this.iMemory] = [];
      this.listOfListsOfUserNames[this.iMemory].push("StavoviZaposlenih");
      this.listOfListsOfUserNames[this.iMemory].push("FeedBack");
      this.listOfListsOfUserNames[this.iMemory].push("Kpi");
      this.listOfListsOfUserNames[this.iMemory].push("ProcenaEdukacije");
      for (let i = 0; i < this.usersSameCompany.length; i++) {
        let stringname = this.usersSameCompany[i].name;
        this.listOfListsOfUserNames[this.iMemory].push(stringname);
      }
    },
  },
  async created() {
    await this.getProfile();

    await this.getCompanies();
    //await this.getAllUsers();

    //Fill list of companies (companyList is used by Tabs)
    this.companys.forEach((company) => {
      let list = [];
      let list2 = [];
      if (company.name != "admin") {
        this.listOfListsOfUsers.push(list);
        this.listOfListsOfUserNames.push(list2);
        this.companyList.push(company.name);
      }
    });
    for (let i = 0; i < this.listOfListsOfUsers.length; i++) {
      let companyName = this.companyList[i];
      this.listOfListsOfUserNames[i].push("StavoviZaposlenih");
      this.listOfListsOfUserNames[i].push("FeedBack");
      this.listOfListsOfUserNames[i].push("Kpi");
      this.listOfListsOfUserNames[i].push("ProcenaEdukacije");
      for (let j = 0; j < this.users.length; j++) {
        if (this.users[j].type === companyName) {
          this.listOfListsOfUsers[i].push(this.users[j]);
          let stringname = this.users[j].name;
          this.listOfListsOfUserNames[i].push(stringname);
        }
      }
    }
  },
  beforeCreate: function() {
    document.body.className = "other";
  },
};
</script>

<style>
#itemsLi {
  background-color: cyan;
}

.companyNav {
  background-color: green;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  margin: auto;
  margin-top: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
