<template lang="html">

  <div id="pdf">
    <h2>Procena sprovedene edukacije - rezultati upitnika</h2>
    <h4>Broj zaposlenih koji su popunili upitnik: <b>{{submissions}}</b> </h4>
    <h4>Prosečna ocena: <b>{{avg.toFixed(2)}}</b> </h4>

    <p>1. U kojoj meri ciljevi edukacije odgovaraju Vašim potrebama?</p>
    <p><b>{{ans1.toFixed(2)}}</b></p>
    <p>2. U kojoj meri je prezentacija teme bila prikladna?</p>
    <p><b>{{ans2.toFixed(2)}}</b></p>
    <p>3. U kojoj meri ocenjujete stručnost predavača?</p>
    <p><b>{{ans3.toFixed(2)}}</b></p>
    <p>4. U kojoj meri je predavač bio pripremljen?</p>
    <p><b>{{ans4.toFixed(2)}}</b></p>
    <p>5. U kojoj meri smatrate da ćete moći da primenite znanje stečeno na ovoj edukaciji?</p>
    <p><b>{{ans5.toFixed(2)}}</b></p>
    <p>6. Kako ocenjujete kvalitet zadatih vežbi?</p>
    <p><b>{{ans6.toFixed(2)}}</b></p>
    <p>7. Kako ocenjujete izvođenje predavača?</p>
    <p><b>{{ans7.toFixed(2)}}</b></p>
    <p>8. Kako ocenjujete prezentacione veštine predavača?</p>
    <p><b>{{ans8.toFixed(2)}}</b></p>
    <p>9. Kako ocenjujete navedene primere tokom edukacije?</p>
    <p><b>{{ans9.toFixed(2)}}</b></p>


  </div>

    <button type="button" name="button" @click="makePDFVQ">Napravi PDF</button>

    <div class="answers" id="ans10">


  <h4>10. Navedite teme za koje smatrate da su bile nevažne za edukaciju i obrazložite odgovor:</h4>
  <div class="holder" v-for="(ans,i) in ans10" :key="i">
    <p>{{i+1}}. {{ans}}</p>
  </div>

  </div>
  <button type="button" name="button" @click="makePDFVQ1">Napravi PDF</button>
  <div class="answers" id="ans11">


<h4>11. Navedite dodatne teme za koje mislite da bi trebalo da se uključe u edukacije:</h4>
<div class="holder" v-for="(ans,i) in ans11" :key="i">
  <p>{{i+1}}. {{ans}}</p>
</div>

</div>
<button type="button" name="button" @click="makePDFVQ2">Napravi PDF</button>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';

export default {
  props:{
    company:{
      type: Object
    }
  },
computed: mapGetters(['usersSameCompany','user','companys']),
  data(){
    return{
      ans1:0,
      ans2:0,
      ans3:0,
      ans4:0,
      ans5:0,
      ans6:0,
      ans7:0,
      ans8:0,
      ans9:0,
      ans10:[],
      ans11:[],
      submissions:0,
      avg:0,
    }
  },
  methods:{
    ...mapActions(['getCollegues','getProfile','getCompanies']),
    async makePDFVQ(){

      var downloading = document.getElementById("pdf");
      var doc = new jsPDF("p","pt","a3");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        height:1190,
        width:840,
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',0,0,840, 1190);
      });

      doc.save("Document.pdf");

    },
    async makePDFVQ1(){

      var downloading = document.getElementById("ans10");
      var doc = new jsPDF("p","pt","a3");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        height:1190,
        width:840,
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',0,0,840, 1190);
      });

      doc.save("Document.pdf");

    },
    async makePDFVQ2(){

      var downloading = document.getElementById("ans11");
      var doc = new jsPDF("p","pt","a3");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        height:1190,
        width:840,
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',0,0,840, 1190);
      });

      doc.save("Document.pdf");

    },
  },
  async created(){
    this.ans1=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans1;
    this.ans2=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans2;
    this.ans3=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans3;
    this.ans4=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans4;
    this.ans5=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans5;
    this.ans6=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans6;
    this.ans7=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans7;
    this.ans8=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans8;
    this.ans9=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans9;

    this.submissions=this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].submisions;

    this.avg = (this.ans1+this.ans2+this.ans3+this.ans4+this.ans5+this.ans6+this.ans7+this.ans8+this.ans9)/9;

    let tempString = this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans10;
    let tempSingle ="";
    let startPos=0;

    for (var i = 0; i < tempString.length; i++) {
      if(tempString[i]=='&' && tempString[i+1]=='&'){
        tempSingle=tempString.slice(startPos,i);
        startPos=i+2;
        this.ans10.push(tempSingle);
      }
    }

     startPos=0;
     tempString = this.company.upitnikEdukacija[this.company.upitnikEdukacija.length-1].ans11;
    for ( i = 0; i < tempString.length; i++) {
      if(tempString[i]=='&' && tempString[i+1]=='&'){
        tempSingle=tempString.slice(startPos,i);
        startPos=i+2;
        this.ans11.push(tempSingle);
      }
    }


  },
  beforeCreate: function() {
        document.body.className = 'other';
    }
}
</script>

<style lang="css" scoped>
#pdf{
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: small;
  margin: 20px;
  padding: 15px;
  text-align: center;

  height:1190px;
  width:840px;
}
.answers{
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: small;
  margin: 20px;
  padding: 15px;
  text-align: center;

  height:1190px;
  width:840px;
}
.holder{
  text-align: left;
}

table{
  border: 2px solid black;
  text-align: center;
  margin:auto;
}
td, th{
  border: 1px solid black;
  text-align: center;
}
tr:nth-child(even){background-color: #aaaacc;}
tr:nth-child(odd){background-color: #ccccff;}
tr:hover {background-color: #afa;}
</style>
