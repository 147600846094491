<template lang="html">
  <div class="form-group">
    <!-- <label for="collegue">Datum evaluacije:</label> -->
    <!-- -->

    <!-- <select  v-model="evaluationIndex">
       <option name="collegue" id="collegue" v-for="ev in companyEvals" v-bind:key="ev"  @click="loadEval(ev.pointer, ev.date)">{{ev.date}}</option>
    </select> -->

    <button
      type="button"
      name="button"
      @click="evalFlag = !evalFlag"
      :class="companyEvals.length > 0 ? 'bg-success m-4' : 'bg-danger m-4'"
    >
      {{ companyEvals.length > 0 ? "Izaberi evaluaciju" : "Nema evaluacije" }}
    </button>
    <div class="" v-if="evalFlag">
      <ul>
        <li
          v-for="(ev, i) in companyEvals"
          @click="loadEval(ev.pointer, ev.date)"
          v-bind:key="i"
          class="evalElement"
        >
          {{ ev.date }}
        </li>
      </ul>
    </div>
    <!-- <button type="button" name="button" @click="">IZABERI</button> -->
  </div>

  <div class="mt-5 mb-2">
    <button
      type="button"
      name="button"
      :disabled="selectedBatch == 0"
      @click="selectedBatch--"
    >
      PRETHODNI
    </button>
    <button
      class="mx-2"
      type="button"
      name="button"
      :disabled="selectedBatch == batches.length - 1"
      @click="selectedBatch++"
    >
      SLEDEĆI
    </button>
    <button type="button" name="button" @click="makePDF('evaluation1')">
      NAPRAVI PDF
    </button>
  </div>

  <div id="evaluation1" class="eval">
    <div class="header">
      <h2>
        {{ company.name }} ({{ selectedBatch * 20 + 1 }}-{{
          (selectedBatch + 1) * 20
        }})
      </h2>
      <h3>
        Procena radnog učinka rađenog
        {{ date?.split(" ")[0].toLocaleLowerCase() }}
        {{ selectedYear }}
      </h3>
    </div>
    <!-- <div class="container"> -->
    <div class="left">
      <table>
        <tr>
          <th>Ime i prezime</th>
          <th>Radno mesto</th>
          <th>Ocena Konačnog učinka</th>
          <th>Kompetence</th>
          <th>Lični</th>
          <th>Kompanijski</th>
        </tr>

        <tr v-for="(ev, i) in batches[selectedBatch]" v-bind:key="ev">
          <td>{{ batches[selectedBatch][i].name }}</td>
          <td>{{ batches[selectedBatch][i].radnoMesto }}</td>

          <td class="red" v-if="batches[selectedBatch][i].kpi <= 0.755">
            {{ batches[selectedBatch][i].kpi }}
          </td>
          <td
            class="yellow"
            v-if="
              batches[selectedBatch][i].kpi > 0.755 &&
                batches[selectedBatch][i].kpi <= 0.995
            "
          >
            {{ batches[selectedBatch][i].kpi }}
          </td>
          <td
            class="blue"
            v-if="
              batches[selectedBatch][i].kpi > 0.995 &&
                batches[selectedBatch][i].kpi < 1.175
            "
          >
            {{ batches[selectedBatch][i].kpi.toFixed(2) }}
          </td>
          <td class="green" v-if="batches[selectedBatch][i].kpi >= 1.175">
            {{ batches[selectedBatch][i].kpi }}
          </td>

          <td v-if="batches[selectedBatch][i].kompetence != '-'">
            {{ batches[selectedBatch][i].kompetence.toFixed(2) }}
          </td>
          <td v-if="batches[selectedBatch][i].kompetence == '-'">
            {{ batches[selectedBatch][i].kompetence }}
          </td>
          <td v-if="batches[selectedBatch][i].licni != '-'">
            {{ batches[selectedBatch][i].licni.toFixed(2) }}
          </td>
          <td v-if="batches[selectedBatch][i].licni == '-'">
            {{ batches[selectedBatch][i].licni }}
          </td>
          <td v-if="batches[selectedBatch][i].kompanijski != '-'">
            {{ batches[selectedBatch][i].kompanijski.toFixed(2) }}
          </td>
          <td v-if="batches[selectedBatch][i].kompanijski == '-'">
            {{ batches[selectedBatch][i].kompanijski }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import html2canvas from "html2canvas";
// eslint-disable-next-line no-unused-vars
import jsPDF from "jspdf";

export default {
  props: {
    company: {
      type: Object,
    },
  },
  computed: mapGetters(["usersSameCompany"]),
  data() {
    return {
      collaguesNumber: 0,
      lastEval: 0,
      date: "",
      selectedYear: null,
      evals: [],
      batches: [],
      selectedBatch: 0,
      evaluationIndex: 0,
      companyEvals: [],
      totalEvals: 0,
      evalFlag: false,
    };
  },
  methods: {
    ...mapActions(["getCollegues"]),
    async makePDF(id) {
      var downloading = document.getElementById(id);
      var doc = new jsPDF("p", "pt", "a3");

      await html2canvas(downloading, {
        scale: 2,
        allowTaint: true,
        useCORS: true,
        height: 1190,
        width: 840,
      }).then((canvas) => {
        doc.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 840, 1190);
      });

      doc.save("Document.pdf");
    },
    compare(a, b) {
      if (a.kpi > b.kpi) return -1;
      if (a.kpi < b.kpi) return 1;
      return 0;
    },
    makeDate() {
      console.log("date START");
      let tempIndex = 3;
      if (this.date[1] == ".") {
        tempIndex = 2;
      }
      if (this.date[tempIndex] == "1") {
        this.date = "Januara 2024.";
      }
      if (this.date[tempIndex] == "2") {
        this.date = "Februara 2024.";
      }
      if (this.date[tempIndex] == "3") {
        this.date = "Marta 2024.";
      }
      if (this.date[tempIndex] == "4") {
        this.date = "Aprila 2024.";
      }
      if (this.date[tempIndex] == "5") {
        this.date = "Maja 2024.";
      }
      if (this.date[tempIndex] == "6") {
        this.date = "Juna 2024.";
      }
      if (this.date[tempIndex] == "7") {
        this.date = "Jula 2024.";
      }
      if (this.date[tempIndex] == "8") {
        this.date = "Avgusta 2024.";
      }
      if (this.date[tempIndex] == "9") {
        this.date = "Septembra 2024.";
      }
      if (this.date[tempIndex] == "10") {
        this.date = "Oktobra 2024.";
      }
      if (this.date[tempIndex] == "11") {
        this.date = "Novembra 2024.";
      }
      if (this.date[tempIndex] == "12") {
        this.date = "Decembra 2024.";
      }
    },
    test(event) {
      console.log("TESTTTT", event);
    },
    loadEval(pointer, date) {
      let index = pointer;
      this.date = date;
      this.selectedYear = date?.split(".")[2];
      console.log("LOAD EVAL", date);
      this.evals = [];
      this.batches = [];
      this.evaluationIndex = index;
      this.lastEval = 0;
      this.makeDate();

      if (this.usersSameCompany[0].performanceEvaluation.length > 0) {
        console.log("LENGTH: ", this.company.performanceEvaluation.length);
        let companyKpiConnector = 0;

        for (var i = 0; i < this.usersSameCompany.length; i++) {
          if (
            this.usersSameCompany[i].performanceEvaluation.length >=
            this.totalEvals - index
          ) {
            if (
              this.usersSameCompany[i].performanceEvaluation[
                index -
                  (this.totalEvals -
                    this.usersSameCompany[i].performanceEvaluation.length)
              ].companyKpiConnector
            ) {
              companyKpiConnector = this.usersSameCompany[i]
                .performanceEvaluation[
                index -
                  (this.totalEvals -
                    this.usersSameCompany[i].performanceEvaluation.length)
              ].companyKpiConnector;
            }
          }
        }

        if (this.company.performanceEvaluation.length >= companyKpiConnector) {
          for (i = 0; i < this.usersSameCompany.length; i++) {
            let tempUser = this.usersSameCompany[i];
            let ukupnoKompetence = 0;
            let ukupnoLicni = 0;
            let ukupnoKompanijski = 0;
            //  for (var z = 0; z < tempUser.performanceEvaluation.length; z++) {
            if (
              tempUser.performanceEvaluation.length >=
              this.totalEvals - index
            ) {
              let pointer =
                index -
                (this.totalEvals - tempUser.performanceEvaluation.length);

              if (
                this.company.performanceEvaluation[companyKpiConnector]
                  .kompetenceProcenat > 0
              ) {
                for (
                  var j = 0;
                  j < tempUser.performanceEvaluation[pointer].kompetence.length;
                  j++
                ) {
                  if (
                    tempUser.performanceEvaluation[pointer].kompetence[j]
                      .konacnaProcena != null
                  ) {
                    ukupnoKompetence +=
                      tempUser.performanceEvaluation[pointer].kompetence[j]
                        .rangiranje;
                  }
                }
              }

              //licni

              if (
                this.company.performanceEvaluation[companyKpiConnector]
                  .licniProcenat > 0
              ) {
                for (
                  j = 0;
                  j < tempUser.performanceEvaluation[pointer].licniKpi.length;
                  j++
                ) {
                  if (
                    tempUser.performanceEvaluation[pointer].licniKpi[j]
                      .konacnaProcena != null
                  ) {
                    ukupnoLicni +=
                      tempUser.performanceEvaluation[pointer].licniKpi[j]
                        .rangiranje;
                  }
                }
              }
              //kompanijski
              if (
                this.company.performanceEvaluation[companyKpiConnector]
                  .kompanijskiProcenat > 0
              ) {
                for (
                  j = 0;
                  j <
                  tempUser.performanceEvaluation[pointer].kompanijski.length;
                  j++
                ) {
                  if (
                    tempUser.performanceEvaluation[pointer].kompanijski[j]
                      .konacnaProcena != null
                  ) {
                    ukupnoKompanijski +=
                      tempUser.performanceEvaluation[pointer].kompanijski[j]
                        .rangiranje;
                  }
                }
              }
            }

            let ukupnoKpi = 0;
            ukupnoKpi =
              (this.company.performanceEvaluation[companyKpiConnector]
                .kompetenceProcenat /
                100) *
                ukupnoKompetence +
              (this.company.performanceEvaluation[companyKpiConnector]
                .licniProcenat /
                100) *
                ukupnoLicni +
              (this.company.performanceEvaluation[companyKpiConnector]
                .kompanijskiProcenat /
                100) *
                ukupnoKompanijski;

            ukupnoKpi = Math.round(ukupnoKpi * 100) / 100;
            let element = {
              kpi: ukupnoKpi,
              id: tempUser._id,
              name: tempUser.name,
              radnoMesto: tempUser.radnoMesto,
              kompetence: ukupnoKompetence,
              licni: ukupnoLicni,
              kompanijski: ukupnoKompanijski,
            };

            if (
              this.company.performanceEvaluation[companyKpiConnector]
                .kompetenceProcenat == 0
            )
              element.kompetence = "-";
            else Math.round(element.kompetence * 100) / 100;
            if (
              this.company.performanceEvaluation[companyKpiConnector]
                .licniProcenat == 0
            )
              element.licni = "-";
            else Math.round(element.licni * 100) / 100;
            if (
              this.company.performanceEvaluation[companyKpiConnector]
                .kompanijskiProcenat == 0
            )
              element.kompanijski = "-";
            else Math.round(element.kompanijski * 100) / 100;

            if (ukupnoKpi != 0) this.evals.push(element);
          }

          this.evals.sort(this.compare);

          console.log("EVALS: ", this.evals);
          for (i = 0; i < this.evals.length; i++) {
            if (!this.evals[i].kpi) {
              this.evals.splice(i, 1);
              i--;
            } else if (this.evals[i].kpi == 0) {
              this.evals.splice(i, 1);
              i--;
            }
          }

          // this.evals.splice(10,this.evals.length);

          for (i = 0; i < this.evals.length; i += 20) {
            let tempArr = [];
            for (j = 0; j < 20; j++) {
              if (i + j < this.evals.length) tempArr.push(this.evals[i + j]);
            }

            this.batches.push(tempArr);
          }
          console.log("batches", this.batches);
        }
      }
      ///////////////////////////////////// KRAJ LOAD
    },
  },

  async created() {
    await this.getCollegues(this.company.name);
    this.totalEvals = this.usersSameCompany[0].performanceEvaluation.length;
    for (var i = 0; i < this.totalEvals; i++) {
      let tempDate = "<nema datuma>";
      for (var j = 0; j < this.usersSameCompany.length; j++) {
        if (
          this.usersSameCompany[j].performanceEvaluation.length >=
          this.totalEvals - i
        ) {
          if (
            this.usersSameCompany[j].performanceEvaluation[
              i -
                (this.totalEvals -
                  this.usersSameCompany[j].performanceEvaluation.length)
            ].date
          ) {
            tempDate = this.usersSameCompany[j].performanceEvaluation[
              i -
                (this.totalEvals -
                  this.usersSameCompany[j].performanceEvaluation.length)
            ].date;
            break;
          }
        }
      }

      this.companyEvals.push({ pointer: i, date: tempDate });
    }

    console.log("THIS COMPANY: ", this.company);

    // if(this.company.performanceEvaluation){
    //   if(this.company.performanceEvaluation.length> 0){
    //     for (var x = 0; x < this.company.performanceEvaluation.length; x++) {
    //       this.companyEvals.push(x);
    //     }
    //
    //     this.evaluationIndex = this.company.performanceEvaluation.length-1;
    //     this.loadEval(this.evaluationIndex);
    //   }
    // }
  },
};
</script>

<style lang="css" scoped>
#evaluation1 {
}
.eval {
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 30px;

  height: 1190px;
  width: 840px;
}
.red {
  background-color: #ffc2c2;
}
.yellow {
  background-color: #ffffc2;
}
.blue {
  background-color: #aaccff;
}
.green {
  background-color: #c2ffc2;
}
.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
.header {
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 30px;

  text-align: center;
  margin-bottom: 10px;
}
.left {
  margin-right: 10px;
}

table {
  border: 2px solid black;
  text-align: center;
  margin: auto;
}
td,
th {
  border: 1px solid black;
  text-align: center;
}
th {
  padding: 2px;
}
.evalElement {
  cursor: pointer;
}
.evalElement:hover {
  background-color: cyan;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
