<template lang="html">
  <div class="" style="width: 70%">



  <h2>Demografija</h2>

<div id="groupPDF">

  <div id="izvestaj">
    <h1 id="korpH1">Izveštaj - istraživanje stavova zaposlenih</h1>
    <h3>Broj zaposlenih koji su popunili upitnik: {{numberofAns}}/{{expectedAns}}({{percentAns.toFixed(2)}}%)</h3>
  </div>

    <div id="staz">

      <div class="stazH1">
      <h3>Radni Staž</h3>
      </div>

      <div class="stazTable">
      <table>
        <tr>
          <th>Staž</th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>manje od 6 meseci</td>
           <td>{{this.company.stavoviZaposlenih.staz.a}}</td>
           <td>{{((this.company.stavoviZaposlenih.staz.a/this.company.stavoviZaposlenih.numberOfAnswers)*100 ).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>6 meseci do 1god.</td>
            <td>{{this.company.stavoviZaposlenih.staz.b}}</td>
            <td>{{((this.company.stavoviZaposlenih.staz.b/this.company.stavoviZaposlenih.numberOfAnswers)*100 ).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>1god. do 2 god.</td>
            <td>{{this.company.stavoviZaposlenih.staz.c}}</td>
            <td>{{((this.company.stavoviZaposlenih.staz.c/this.company.stavoviZaposlenih.numberOfAnswers)*100 ).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>više od 2god.</td>
            <td>{{this.company.stavoviZaposlenih.staz.d}}</td>
            <td>{{((this.company.stavoviZaposlenih.staz.d/this.company.stavoviZaposlenih.numberOfAnswers)*100 ).toFixed(2)}}</td>
        </tr>
      </table>
      </div>

      <div id="stazGraph">
      <bar-chart :data="chartDataStaz" id="stazChart" :library="options"> </bar-chart>
      </div>

    </div>

<!-- <button @click="makePDF1">MakePDF</button> -->

    <div id="pol">

      <div class="polH1">
        <h3>Pol</h3>
      </div>
      <div class="polTable">
        <table>
          <tr>
            <th>Pol</th>
            <th>Broj Zaposlenih</th>
            <th>Procenat Zaposlenih(%)</th>
          </tr>
          <tr>
             <td>Muški</td>
             <td>{{this.company.stavoviZaposlenih.pol.a}}</td>
             <td>{{((this.company.stavoviZaposlenih.pol.a/this.company.stavoviZaposlenih.numberOfAnswers)*100 ).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Ženski</td>
              <td>{{this.company.stavoviZaposlenih.pol.b}}</td>
              <td>{{((this.company.stavoviZaposlenih.pol.b/this.company.stavoviZaposlenih.numberOfAnswers)*100 ).toFixed(2)}}</td>
          </tr>
        </table>
      </div>
      <div class="polGraph">
        <bar-chart :data="chartDataPol" id="polChart" :library="options"> </bar-chart>
      </div>

    </div>

<!-- <button @click="makePDF2">MakePDF</button> -->




  <!-- <h2>Korporativna Kultura</h2> -->
   <div class="korpKultura">
     <h1 id="korpH1">Korporativna Kultura</h1>
   </div>

    <div class="yesnoQuestion">
      <div class="yesnoH1">
        <h3>1.	Da li smatrate da su vam kompanijske vrednosti potrebne?</h3>
      </div>
      <div class="yesnoTable">
        <table>
          <tr>
            <th></th>
            <th>Broj Zaposlenih</th>
            <th>Procenat Zaposlenih(%)</th>
          </tr>
          <tr>
             <td>Da</td>
             <td>{{this.company.stavoviZaposlenih.ans11}}</td>
             <td>{{((this.company.stavoviZaposlenih.ans11/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Ne</td>
              <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans11}}</td>
              <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans11)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
        </table>
      </div>
        <div class="yesnoGraph">
          <bar-chart :data="chartDataAns11" :library="options" ></bar-chart>
        </div>
    </div>



    <div class="stringquestion">
      <div class="stringH1">
        <h3>2. Ukoliko ste na prvo pitanje odgovorili sa DA, navedite koje kompanijske vrednosti prema vašem  mišljenju treba da poseduje kompanija:</h3>
      </div>
      <div class="stringField">
        <p v-for="(p,i) in prva" :key="i">{{i+1}}.{{p}}</p>
      </div>

    </div>


    <div class="yesnoQuestion">
      <div class="yesnoH1">
        <h3>3. Da li kompanija poseduje paket benefita za zaposlene?</h3>
      </div>
      <div class="yesnoTable">
        <table>
          <tr>
            <th></th>
            <th>Broj Zaposlenih</th>
            <th>Procenat Zaposlenih(%)</th>
          </tr>
          <tr>
             <td>Da</td>
             <td>{{this.company.stavoviZaposlenih.ans13}}</td>
             <td>{{((this.company.stavoviZaposlenih.ans13/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Ne</td>
              <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans13}}</td>
              <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans13)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
        </table>
      </div>
        <div class="yesnoGraph">
          <bar-chart :data="chartDataAns13" :library="options" ></bar-chart>
        </div>
    </div>

    <div class="yesnoQuestion">
      <div class="yesnoH1">
        <h3>4. Obeležite 2 beneficije koje biste želeli da vam kompanija u buducem periodu obezbedi:</h3>
        <!-- ćem periodu obezbedi: -->
      </div>
      <div class="yesnoTable">
        <table>
          <tr>
            <th></th>
            <th>Broj Zaposlenih</th>
            <th>Procenat Zaposlenih(%)</th>
          </tr>
          <tr>
             <td>Dodatni slobodni dani</td>
             <td>{{this.company.stavoviZaposlenih.ans14.a}}</td>
             <td>{{((this.company.stavoviZaposlenih.ans14.a/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Teambuilding aktivnosti</td>
              <td>{{this.company.stavoviZaposlenih.ans14.b}}</td>
              <td>{{((this.company.stavoviZaposlenih.ans14.b/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Clanstvo u fitness centrima</td>
              <td>{{this.company.stavoviZaposlenih.ans14.c}}</td>
              <td>{{((this.company.stavoviZaposlenih.ans14.c/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Privatno zdravstveno osiguranje</td>
              <td>{{this.company.stavoviZaposlenih.ans14.d}}</td>
              <td>{{((this.company.stavoviZaposlenih.ans14.d/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
          <tr>
              <td>Dodatno strucno usavršavanje</td>
              <td>{{this.company.stavoviZaposlenih.ans14.e}}</td>
              <td>{{((this.company.stavoviZaposlenih.ans14.e/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
          </tr>
        </table>
      </div>
        <div class="yesnoGraph">
          <bar-chart :data="chartDataAns14" :library="options" ></bar-chart>
        </div>
    </div>





<div class="korpKultura">
  <h1>Medjuljudski Odnosi</h1>
</div>


  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1.	U kojoj meri je prisutan timski rad u kompaniji? </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Prisutan</td>
           <td>{{this.company.stavoviZaposlenih.ans21.a}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans21.a/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Delimicno prisutan</td>
            <td>{{this.company.stavoviZaposlenih.ans21.b}}</td>
            <td>{{((this.company.stavoviZaposlenih.ans21.b/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Nije prisutan</td>
            <td>{{this.company.stavoviZaposlenih.ans21.c}}</td>
            <td>{{((this.company.stavoviZaposlenih.ans21.c/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns21" :library="options" ></bar-chart>
      </div>
  </div>


  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2.	Da li imate prijatelje medju kolegama?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans22}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans22/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans22}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans22)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns22" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3.	Zadovoljan/na sam komunikacijom sa mojim pretpostavljenim.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans23}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans23/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans23}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans23)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns23" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4.	Zadovoljan/na sam komunikacijom sa kolegama.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans24}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans24/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans24}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans24)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns24" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>5.	Zaposleni imaju osecaj zajedništva i medjuzavisnosti.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans25}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans25/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans25}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans25)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns25" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>6. Kako biste ocenili radnu atmosferu?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Veoma prijatna</td>
           <td>{{this.company.stavoviZaposlenih.ans26.a}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans26.a/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Prijatna</td>
           <td>{{this.company.stavoviZaposlenih.ans26.b}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans26.b/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Neprijatna</td>
           <td>{{this.company.stavoviZaposlenih.ans26.c}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans26.c/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Veoma neprijatna</td>
           <td>{{this.company.stavoviZaposlenih.ans26.d}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans26.d/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>

      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns26" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>7. Da li se družite sa kolegama i van radnog vremena?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans27}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans27/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans27}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans27)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns27" :library="options" ></bar-chart>
      </div>
  </div>


  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>8. Da li se cesto javljaju konflikti medju zaposlenima?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans28}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans28/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans28}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans28)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns28" :library="options" ></bar-chart>
      </div>
  </div>



<div class="korpKultura">
<h1>Korporativna Komunikacija</h1>
</div>


  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1.	Da li ste upoznati sa poslovnim rezultatima kompanije? </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans31}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans31/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans31}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans31)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns31" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2.	Da li ste upoznati sa radom drugih kolega? </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans32}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans32/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans32}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans32)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns32" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3.	Da li smatrate da su Vam sve neophodne informacije u vezi kompanije na raspolaganju? </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans33}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans33/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans33}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans33)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns33" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4.	Da li se zaposleni u kompaniji pitaju za mišljenje?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans34}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans34/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans34}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans34)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns34" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>5. Da li zaposleni imaju uticaja na odluke koje se donose?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans35}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans35/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans35}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans35)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns35" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>6. Da li znate strategiju kompanije?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans36}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans36/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans36}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans36)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns36" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>7. Da li se poštuju Zakonom zagarantovana prava zaposlenih?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans37}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans37/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans37}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans37)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns37" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>8. Da li ste upoznati sa strategijom vaše organizacione celine?</h3>
      <!-- vase organizacione -->
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans38}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans38/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans38}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans38)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns38" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>9. Da li ste upoznati sa radom drugih organizacionih celina?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans39}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans39/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans39}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans39)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns39" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>10.  U kompaniji se ne zna ko šta radi.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans310}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans310/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans310}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans310)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns310" :library="options" ></bar-chart>
      </div>
  </div>
  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>11.  Zaposleni vode racuna o ugledu kompanije.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans311}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans311/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans311}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans311)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns311" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="korpKultura">
    <h1 id="korpH1">Organizacija Rada</h1>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1. Zaposlenima su obezbedjeni dobri radni uslovi.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans41}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans41/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans41}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans41)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns41" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2. Zaposleni koriste savremene uredjaje i opremu.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans42}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans42/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans42}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans42)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns42" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3. Da li ste zadovoljni radnim prostorom u kom obavljate posao?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans43}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans43/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans43}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans43)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns43" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4. Zaposlenima je obezbedjen parking prostor.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans44}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans44/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans44}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans44)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns44" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>5. Posao je dobro organizovan.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans45}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans45/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans45}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans45)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns45" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>6. Broj zaposlenih je uskladjen sa obimom posla.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans46}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans46/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans46}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans46)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns46" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>7. Zaposleni su posveceni svom poslu.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans47}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans47/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans47}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans47)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns47" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>8. Tacno znam koji su moji radni zadaci. </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans48}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans48/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans48}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans48)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns48" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>9. Tacno znam koji su mi zadaci prioritetni.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans49}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans49/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans49}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans49)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns49" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>10. Od nadredjenog dobijam jasne instrukcije u vezi zadataka.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans410}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans410/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans410}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans410)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns410" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>11. Smatram da imam podršku od strane nadredjenog u izvršavanju zadataka.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans411}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans411/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans411}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans411)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns411" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>12. Svoje radne zadatke uspevam da završim u okviru radnog vremena.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans412}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans412/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans412}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans412)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns412" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>13. Ostajem prekovremeno na poslu.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans413}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans413/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans413}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans413)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns413" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>14. Ako ostajem prekovremeno na poslu, ostajem da bih:</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Stigao-la da obavim sve zadatke</td>
           <td>{{this.company.stavoviZaposlenih.ans414.a}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans414.a/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Da bih postigao-la bolje rezultate</td>
           <td>{{this.company.stavoviZaposlenih.ans414.b}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans414.b/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Jer se to ocekuje od zaposlenih </td>
           <td>{{this.company.stavoviZaposlenih.ans414.c}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans414.c/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Bez odgovora</td>
           <td>{{this.company.stavoviZaposlenih.ans414.d}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans414.d/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns414" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>15. Zaposleni uspevaju da usklade poslovni i privatni život.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans415}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans415/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans415}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans415)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns415" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>16.  Zaposleni su blagovremeno informisani o odlukama koje uticu na njihov rad.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans416}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans416/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans416}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans416)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns416" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="korpKultura">
  <h1>Razvojne Mogucnosti</h1>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1. Razmišljam da odem iz kompanije.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans51}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans51/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans51}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans51)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns51" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2. Ocekujem da ce mi primanja rasti.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans52}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans52/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans52}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans52)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns52" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3. Tacno znam šta treba da radim da bi napredovao/la u kompaniji.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans53}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans53/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans53}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans53)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns53" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4.	Kompanija mi omogucava strucno usavršavanje.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans54}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans54/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans54}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans54)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns54" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>5.	Zaposleni sticu dragoceno iskustvo radeci za kompaniju.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans55}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans55/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans55}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans55)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns55" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>6.	Priroda posla je takva da angažuje sposobnosti i znanja zaposlenih.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans56}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans56/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans56}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans56)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns56" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="korpKultura">
  <h1>Zadovoljstvo radnim mestom</h1>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1.	Volim svoj posao.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans61}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans61/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans61}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans61)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns61" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2.	Moje radno mesto je važno za uspeh kompanije.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans62}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans62/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans62}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans62)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns62" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3.	Svi zaposleni imaju isti tretman.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans63}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans63/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans63}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans63)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns63" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4.	Imam svu neophodnu opremu za rad.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans64}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans64/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans64}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans64)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns64" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>5.	Zadovoljan/na sam radnim prostorom u kojem radim. </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans65}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans65/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans65}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans65)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns65" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>6.	Moj posao je monoton.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans66}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans66/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans66}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans66)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns66" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>7.	U kompaniji za koju radim vidim svoju dugorocnu perspektivu.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans67}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans67/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans67}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans67)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns67" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>8.	Da li je vaša kompanija prepoznata na tržištu rada kao poželjan Poslodavac?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans68}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans68/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans68}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans68)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>

    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns68" :library="options" ></bar-chart>
      </div>
  </div>



  <div class="korpKultura">
  <h1>Zadovoljstvo materijalnim aspektom</h1>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1.	Sistem nagradjivanja u kompaniji je adekvatan.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans71}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans71/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans71}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans71)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns71" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2.	Moje radno mesto je vrednovano na pravi nacin.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans72}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans72/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans72}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans72)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns72" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3.	Koji vid licne stimulacije po vašem mišljenju ima najviše efekta? </h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Mesecna stimulacija</td>
           <td>{{this.company.stavoviZaposlenih.ans73.a}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans73.a/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Kvartalni bonus</td>
           <td>{{this.company.stavoviZaposlenih.ans73.b}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans73.b/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Godišnji bonus </td>
           <td>{{this.company.stavoviZaposlenih.ans73.c}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans73.c/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Pohvala i javno priznanje</td>
           <td>{{this.company.stavoviZaposlenih.ans73.d}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans73.d/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Nemam definisan stav o tome</td>
           <td>{{this.company.stavoviZaposlenih.ans73.e}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans73.e/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns73" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4.	Da li smatrate da se vaš rad, ucinak i zalaganje prate i vrednuju objektivno i pravedno?</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans74}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans74/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans74}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans74)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns74" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="korpKultura2">
  <h1>Lojalnost zaposlenih</h1>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1.	Rangirajte (poredjajte po važnosti ocenom od 1 (najmanje važno) do 5 (najvažnije)) znacaj sledecih faktora kada je u pitanju posao (visina plate, dobra organizacija, sigurnost, medjuljudski odnosi, mogucnost napredovanja):</h3>
      <h3>(Na grafiku se prikazuje prosecna ocena)</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th>(rang = 1. znaci najvise zastupljen)</th>
          <th>Rang</th>
        </tr>
        <tr>
           <td>Visina plate</td>
           <td>{{rankovi[0]}}.</td>
           <!-- <td>{{(this.company.stavoviZaposlenih.ans81.a/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2)}}</td> -->
        </tr>
        <tr>
           <td>Dobra organizacija</td>
           <td>{{rankovi[1]}}.</td>
           <!-- <td>{{(this.company.stavoviZaposlenih.ans81.b/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2)}}</td> -->
        </tr>
        <tr>
           <td>Sigurnost</td>
           <td>{{rankovi[2]}}.</td>
           <!-- <td>{{(this.company.stavoviZaposlenih.ans81.c/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2)}}</td> -->
        </tr>
        <tr>
           <td>Medjuljudski odnosi</td>
           <td>{{rankovi[3]}}.</td>
           <!-- <td>{{(this.company.stavoviZaposlenih.ans81.d/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2)}}</td> -->
        </tr>
        <tr>
           <td>Mogucnost napredovanja</td>
           <td>{{rankovi[4]}}.</td>
           <!-- <td>{{(this.company.stavoviZaposlenih.ans81.e/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2)}}</td> -->
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns81" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>2. Zaposleni ne strahuju za sigurnost svog radnog mesta.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans82}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans82/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans82}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans82)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns82" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>3. Zaposleni veruju da su oni koji su napustili kompaniju pogrešili.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans83}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans83/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans83}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans83)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns83" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>4. Zaposleni bi preporucili svoju kompaniju onima koji traže posao.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans84}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans84/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans84}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans84)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns84" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>5.	Smatram da je broj ljudi koji napušta kompaniju zanemarljiv.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans85}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans85/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans85}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans85)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns85" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>6.	Smatram da vecina kolega ne planira promenu radnog mesta.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans86}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans86/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans86}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans86)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns86" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>7.	Vecina kolega ima poverenje u rukovodstvo kompanije.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Tacno</td>
           <td>{{this.company.stavoviZaposlenih.ans87.a}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans87.a/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Delimicno tacno</td>
           <td>{{this.company.stavoviZaposlenih.ans87.b}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans87.b/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
           <td>Nije Tacno</td>
           <td>{{this.company.stavoviZaposlenih.ans87.c}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans87.c/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>

      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns87" :library="options" ></bar-chart>
      </div>
  </div>



  <div class="korpKultura">
  <h1>Angažovanje zaposlenih u proces poslovanja</h1>
  </div>


  <div class="yesnoQuestion">
    <div class="yesnoH1">
      <h3>1.	Zaposleni se ohrabruju da daju nove ideje i predloge.</h3>
    </div>
    <div class="yesnoTable">
      <table>
        <tr>
          <th></th>
          <th>Broj Zaposlenih</th>
          <th>Procenat Zaposlenih(%)</th>
        </tr>
        <tr>
           <td>Da</td>
           <td>{{this.company.stavoviZaposlenih.ans91}}</td>
           <td>{{((this.company.stavoviZaposlenih.ans91/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Ne</td>
            <td>{{this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans91}}</td>
            <td>{{(((this.company.stavoviZaposlenih.numberOfAnswers-this.company.stavoviZaposlenih.ans91)/this.company.stavoviZaposlenih.numberOfAnswers)*100).toFixed(2)}}</td>
        </tr>
      </table>
    </div>
      <div class="yesnoGraph">
        <bar-chart :data="chartDataAns91" :library="options" ></bar-chart>
      </div>
  </div>

  <div class="stringquestion">
    <div class="stringH1">
      <h3>2.	Navedite vaše predloge i ideje za poboljšanje efikasnosti poslovanja i bolje organizacije?</h3>
    </div>
    <div class="stringField">
      <p v-for="(p,i) in druga" :key="i">{{i+1}}.{{p}}</p>
      <!-- <p>{{ans92}}</p> -->
    </div>

  </div>



</div>
    <button @click="makePDFGroup">MakePDF</button>

</div>



</template>

<script>
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';
import Chart from 'chart.js';
export default {
  props: {
    company: {
      type: Object
    },
  },
  data() {
    return {
      expectedAns:0,
      percentAns:0,
      rankovi:[0,0,0,0,0],
      prva:[],
      druga:[],
      numberofAns:0,
      percentageDone:0,
      value: 100,
      max: 300,
      chartDataStaz: {
        'do_6_meseci': 0,
        'od_6_meseci_do_1_godine': 0,
        'od_1_godina_do_2_godine': 0,
        'vise_od_2_godine': 0,
      },
      chartDataPol: {
        'Muski': 0,
        'Zenski': 0,
      },
      options: {
        plug: true,
        scales: {
          yAxes: [{
              ticks: {
                beginAtZero: true
             }
           }]
         }
      },
      chartDataAns11: {
        'DA': 0,
        'NE': 0
      },
      ans12: '',
      ans92: '',
      chartDataAns13: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns14: {
        'Dodatni_slobodni_dani': 0,
        'Teambuilding_aktivnosti': 0,
        'Članstvo_u_fitness_centrima': 0,
        'Privatno_zdravstveno_osiguranje': 0,
        'Dodatno_stručno_usavršavanje': 0
      },
      chartDataAns21: {
        'Prisutan': 0,
        'Delimično_prisutan': 0,
        'Nije_prisutan': 0
      },
      chartDataAns22: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns23: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns24: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns25: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns26: {
        'Veoma_prijatna': 0,
        'Prijatna': 0,
        'Neprijatna': 0,
        'Veoma_neprijatna': 0

      },
      chartDataAns27: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns28: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns31: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns32: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns33: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns34: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns35: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns36: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns37: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns38: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns39: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns310: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns311: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns41: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns42: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns43: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns44: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns45: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns46: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns47: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns48: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns49: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns410: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns411: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns412: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns413: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns414: {
        // 'Stigao_da_obavim_sve_zadatke': 0,
        // 'Da_bih_postigao_bolje_rezultate': 0,
        // 'Jer_se_to_očekuje_od_zaposlenih': 0,
        // 'Bez_odgovora': 0,
        'A':0,
        'B':0,
        'C':0,
        'D':0
      },
      chartDataAns415: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns416: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns51: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns52: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns53: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns54: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns55: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns56: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns61: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns62: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns63: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns64: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns65: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns66: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns67: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns68: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns71: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns72: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns73: {
        'A':0,
        'B':0,
        'C':0,
        'D':0,
        'E':0
      },
      chartDataAns74: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns81: {
        'visina_p':0,
        'dobra_o':0,
        'sigurnost':0,
        'medj_odnosi':0,
        'mogucnost_n':0
      },
      chartDataAns82: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns83: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns84: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns85: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns86: {
        'DA': 0,
        'NE': 0
      },
      chartDataAns87: {
        'Tačno': 0,
        'Delimično_tačno': 0,
        'Nije_tačno' : 0
      },
      chartDataAns91: {
        'DA': 0,
        'NE': 0
      },
    }
  },

  created() {
    this.chartDataStaz.do_6_meseci = this.company.stavoviZaposlenih.staz.a;
    this.chartDataStaz.od_6_meseci_do_1_godine = this.company.stavoviZaposlenih.staz.b;
    this.chartDataStaz.od_1_godina_do_2_godine = this.company.stavoviZaposlenih.staz.c;
    this.chartDataStaz.vise_od_2_godine = this.company.stavoviZaposlenih.staz.d;

    this.chartDataPol.Muski = this.company.stavoviZaposlenih.pol.a;
    this.chartDataPol.Zenski = this.company.stavoviZaposlenih.pol.b;

    this.chartDataAns11.DA = this.company.stavoviZaposlenih.ans11;
    this.chartDataAns11.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans11;

    this.numberofAns = this.company.stavoviZaposlenih.numberOfAnswers;

    if(this.company.name=="TeleGroup Beograd"){
      this.expectedAns = 128;
      this.percentAns = (this.numberofAns/this.expectedAns) *100;
    }
    if(this.company.name=="Visaris"){
      this.expectedAns = 78;
      this.percentAns = (this.numberofAns/this.expectedAns) *100;
    }


    // const words = this.company.stavoviZaposlenih.ans12.split('&');
    // for (var i = 0; i < words.length - 1; i++) {
    //   this.ans12 += +(i + 1) + '. ' + words[i] + '\n';
    // }
    //
    // const words2 = this.company.stavoviZaposlenih.ans92.split('&');
    // for ( i = 0; i < words2.length - 1; i++) {
    //   this.ans92 += +(i + 1) + '. ' + words2[i] + '\n';
    // }

    this.chartDataAns13.DA = this.company.stavoviZaposlenih.ans13;
    this.chartDataAns13.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans13;

    this.chartDataAns14.Dodatni_slobodni_dani = this.company.stavoviZaposlenih.ans14.a;
    this.chartDataAns14.Teambuilding_aktivnosti = this.company.stavoviZaposlenih.ans14.b;
    this.chartDataAns14.Članstvo_u_fitness_centrima = this.company.stavoviZaposlenih.ans14.c;
    this.chartDataAns14.Privatno_zdravstveno_osiguranje = this.company.stavoviZaposlenih.ans14.d;
    this.chartDataAns14.Dodatno_stručno_usavršavanje = this.company.stavoviZaposlenih.ans14.e;

    this.chartDataAns21.Prisutan = this.company.stavoviZaposlenih.ans21.a;
    this.chartDataAns21.Delimično_prisutan = this.company.stavoviZaposlenih.ans21.b;
    this.chartDataAns21.Nije_prisutan = this.company.stavoviZaposlenih.ans21.c;

    this.chartDataAns22.DA = this.company.stavoviZaposlenih.ans22;
    this.chartDataAns22.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans22;

    this.chartDataAns23.DA = this.company.stavoviZaposlenih.ans23;
    this.chartDataAns23.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans23;

    this.chartDataAns24.DA = this.company.stavoviZaposlenih.ans24;
    this.chartDataAns24.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans24;

    this.chartDataAns25.DA = this.company.stavoviZaposlenih.ans25;
    this.chartDataAns25.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans25;


    this.chartDataAns26.Veoma_prijatna = this.company.stavoviZaposlenih.ans26.a;
    this.chartDataAns26.Prijatna = this.company.stavoviZaposlenih.ans26.b;
    this.chartDataAns26.Neprijatna = this.company.stavoviZaposlenih.ans26.c;
    this.chartDataAns26.Veoma_neprijatna = this.company.stavoviZaposlenih.ans26.d;

    this.chartDataAns27.DA = this.company.stavoviZaposlenih.ans27;
    this.chartDataAns27.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans27;

    this.chartDataAns28.DA = this.company.stavoviZaposlenih.ans28;
    this.chartDataAns28.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans28;

    this.chartDataAns31.DA = this.company.stavoviZaposlenih.ans31;
    this.chartDataAns31.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans31;

    this.chartDataAns32.DA = this.company.stavoviZaposlenih.ans32;
    this.chartDataAns32.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans32;

    this.chartDataAns33.DA = this.company.stavoviZaposlenih.ans33;
    this.chartDataAns33.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans33;

    this.chartDataAns34.DA = this.company.stavoviZaposlenih.ans34;
    this.chartDataAns34.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans34;

    this.chartDataAns35.DA = this.company.stavoviZaposlenih.ans35;
    this.chartDataAns35.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans35;

    this.chartDataAns36.DA = this.company.stavoviZaposlenih.ans36;
    this.chartDataAns36.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans36;

    this.chartDataAns37.DA = this.company.stavoviZaposlenih.ans37;
    this.chartDataAns37.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans37;

    this.chartDataAns38.DA = this.company.stavoviZaposlenih.ans38;
    this.chartDataAns38.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans38;

    this.chartDataAns39.DA = this.company.stavoviZaposlenih.ans39;
    this.chartDataAns39.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans39;

    this.chartDataAns310.DA = this.company.stavoviZaposlenih.ans310;
    this.chartDataAns310.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans310;

    this.chartDataAns311.DA = this.company.stavoviZaposlenih.ans311;
    this.chartDataAns311.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans311;

    this.chartDataAns41.DA = this.company.stavoviZaposlenih.ans41;
    this.chartDataAns41.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans41;

    this.chartDataAns42.DA = this.company.stavoviZaposlenih.ans42;
    this.chartDataAns42.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans42;

    this.chartDataAns43.DA = this.company.stavoviZaposlenih.ans43;
    this.chartDataAns43.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans43;

    this.chartDataAns44.DA = this.company.stavoviZaposlenih.ans44;
    this.chartDataAns44.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans44;

    this.chartDataAns45.DA = this.company.stavoviZaposlenih.ans45;
    this.chartDataAns45.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans45;

    this.chartDataAns46.DA = this.company.stavoviZaposlenih.ans46;
    this.chartDataAns46.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans46;

    this.chartDataAns47.DA = this.company.stavoviZaposlenih.ans47;
    this.chartDataAns47.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans47;

    this.chartDataAns48.DA = this.company.stavoviZaposlenih.ans48;
    this.chartDataAns48.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans48;

    this.chartDataAns49.DA = this.company.stavoviZaposlenih.ans49;
    this.chartDataAns49.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans49;

    this.chartDataAns410.DA = this.company.stavoviZaposlenih.ans410;
    this.chartDataAns410.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans410;

    this.chartDataAns411.DA = this.company.stavoviZaposlenih.ans411;
    this.chartDataAns411.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans411;

    this.chartDataAns412.DA = this.company.stavoviZaposlenih.ans412;
    this.chartDataAns412.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans412;

    this.chartDataAns413.DA = this.company.stavoviZaposlenih.ans413;
    this.chartDataAns413.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans413;

    // this.chartDataAns414.Stigao_da_obavim_sve_zadatke = this.company.stavoviZaposlenih.ans414.a;
    // this.chartDataAns414.Da_bih_postigao_bolje_rezultate = this.company.stavoviZaposlenih.ans414.b;
    // this.chartDataAns414.Jer_se_to_očekuje_od_zaposlenih = this.company.stavoviZaposlenih.ans414.c;
    // this.chartDataAns414.Bez_odgovora = this.company.stavoviZaposlenih.ans414.d;

    this.chartDataAns414.A = this.company.stavoviZaposlenih.ans414.a;
    this.chartDataAns414.B = this.company.stavoviZaposlenih.ans414.b;
    this.chartDataAns414.C = this.company.stavoviZaposlenih.ans414.c;
    this.chartDataAns414.D = this.company.stavoviZaposlenih.ans414.d;


    this.chartDataAns415.DA = this.company.stavoviZaposlenih.ans415;
    this.chartDataAns415.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans415;

    this.chartDataAns416.DA = this.company.stavoviZaposlenih.ans416;
    this.chartDataAns416.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans416;

    this.chartDataAns51.DA = this.company.stavoviZaposlenih.ans51;
    this.chartDataAns51.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans51;

    this.chartDataAns52.DA = this.company.stavoviZaposlenih.ans52;
    this.chartDataAns52.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans52;

    this.chartDataAns53.DA = this.company.stavoviZaposlenih.ans53;
    this.chartDataAns53.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans53;

    this.chartDataAns54.DA = this.company.stavoviZaposlenih.ans54;
    this.chartDataAns54.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans54;

    this.chartDataAns55.DA = this.company.stavoviZaposlenih.ans55;
    this.chartDataAns55.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans55;

    this.chartDataAns56.DA = this.company.stavoviZaposlenih.ans56;
    this.chartDataAns56.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans56;


    this.chartDataAns61.DA = this.company.stavoviZaposlenih.ans61;
    this.chartDataAns61.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans61;

    this.chartDataAns62.DA = this.company.stavoviZaposlenih.ans62;
    this.chartDataAns62.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans62;

    this.chartDataAns63.DA = this.company.stavoviZaposlenih.ans63;
    this.chartDataAns63.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans63;

    this.chartDataAns64.DA = this.company.stavoviZaposlenih.ans64;
    this.chartDataAns64.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans64;

    this.chartDataAns65.DA = this.company.stavoviZaposlenih.ans65;
    this.chartDataAns65.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans65;

    this.chartDataAns66.DA = this.company.stavoviZaposlenih.ans66;
    this.chartDataAns66.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans66;

    this.chartDataAns67.DA = this.company.stavoviZaposlenih.ans67;
    this.chartDataAns67.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans67;

    this.chartDataAns68.DA = this.company.stavoviZaposlenih.ans68;
    this.chartDataAns68.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans68;

    this.chartDataAns71.DA = this.company.stavoviZaposlenih.ans71;
    this.chartDataAns71.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans71;

    this.chartDataAns72.DA = this.company.stavoviZaposlenih.ans72;
    this.chartDataAns72.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans72;

    this.chartDataAns73.A = this.company.stavoviZaposlenih.ans73.a;
    this.chartDataAns73.B = this.company.stavoviZaposlenih.ans73.b;
    this.chartDataAns73.C = this.company.stavoviZaposlenih.ans73.c;
    this.chartDataAns73.D = this.company.stavoviZaposlenih.ans73.d;
    this.chartDataAns73.E = this.company.stavoviZaposlenih.ans73.e;

    this.chartDataAns74.DA = this.company.stavoviZaposlenih.ans74;
    this.chartDataAns74.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans74;


    this.chartDataAns81.visina_p = (this.company.stavoviZaposlenih.ans81.a/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2);
    this.chartDataAns81.dobra_o = (this.company.stavoviZaposlenih.ans81.b/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2);
    this.chartDataAns81.sigurnost = (this.company.stavoviZaposlenih.ans81.c/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2);
    this.chartDataAns81.medj_odnosi = (this.company.stavoviZaposlenih.ans81.d/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2);
    this.chartDataAns81.mogucnost_n = (this.company.stavoviZaposlenih.ans81.e/this.company.stavoviZaposlenih.numberOfAnswers).toFixed(2);

    this.chartDataAns82.DA = this.company.stavoviZaposlenih.ans82;
    this.chartDataAns82.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans82;

    this.chartDataAns83.DA = this.company.stavoviZaposlenih.ans83;
    this.chartDataAns83.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans83;

    this.chartDataAns84.DA = this.company.stavoviZaposlenih.ans84;
    this.chartDataAns84.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans84;

    this.chartDataAns85.DA = this.company.stavoviZaposlenih.ans85;
    this.chartDataAns85.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans85;

    this.chartDataAns86.DA = this.company.stavoviZaposlenih.ans86;
    this.chartDataAns86.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans86;

    this.chartDataAns87.Tačno = this.company.stavoviZaposlenih.ans87.a;
    this.chartDataAns87.Delimično_tačno = this.company.stavoviZaposlenih.ans87.b;
    this.chartDataAns87.Nije_tačno = this.company.stavoviZaposlenih.ans87.c;

    this.chartDataAns91.DA = this.company.stavoviZaposlenih.ans91;
    this.chartDataAns91.NE = this.company.stavoviZaposlenih.numberOfAnswers - this.company.stavoviZaposlenih.ans91;


    this.prva  = this.company.stavoviZaposlenih.ans12.split("&");
    this.druga = this.company.stavoviZaposlenih.ans92.split("&");


   let total1 =this.company.stavoviZaposlenih.ans92.split("&");

    console.log("Prvo: ");
    let totalStr="";
    for ( i = 0; i < total1.length; i++) {
      totalStr+=(i+1)+"."+total1[i]+"\n";
    }
    console.log(totalStr);
    // console.log("Drugo: ");
    // for ( i = 0; i < total1.length; i++) {
    //   console.log(total2[i]);
    // }

    for (var i = 0; i < this.prva.length; i++) {
      if(this.prva[i]==""){
        this.prva.splice(i,1);
        i--;
      }
    }

    this.prva.splice(10);

    for ( i = 0; i < this.druga.length; i++) {
      if(this.druga[i]==""){
        this.druga.splice(i,1);
        i--;
      }
    }

    this.druga.splice(3);
  //  this.druga=this.ans92.split(".");

  this.rankovi=[0,0,0,0,0];

  this.company.stavoviZaposlenih.ans81.a
  let tempCounterRank=5;

  if(this.company.stavoviZaposlenih.ans81.a>this.company.stavoviZaposlenih.ans81.b){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.a>this.company.stavoviZaposlenih.ans81.c){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.a>this.company.stavoviZaposlenih.ans81.d){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.a>this.company.stavoviZaposlenih.ans81.e){
    tempCounterRank-=1;
  }
  this.rankovi[0]=tempCounterRank;
  tempCounterRank=5;

  if(this.company.stavoviZaposlenih.ans81.b>this.company.stavoviZaposlenih.ans81.a){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.b>this.company.stavoviZaposlenih.ans81.c){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.b>this.company.stavoviZaposlenih.ans81.d){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.b>this.company.stavoviZaposlenih.ans81.e){
    tempCounterRank-=1;
  }
  this.rankovi[1]=tempCounterRank;
  tempCounterRank=5;

  ///////////

  if(this.company.stavoviZaposlenih.ans81.c>this.company.stavoviZaposlenih.ans81.a){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.c>this.company.stavoviZaposlenih.ans81.b){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.c>this.company.stavoviZaposlenih.ans81.d){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.c>this.company.stavoviZaposlenih.ans81.e){
    tempCounterRank-=1;
  }
  this.rankovi[2]=tempCounterRank;
  tempCounterRank=5;

  /////////////

  if(this.company.stavoviZaposlenih.ans81.d>this.company.stavoviZaposlenih.ans81.a){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.d>this.company.stavoviZaposlenih.ans81.b){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.d>this.company.stavoviZaposlenih.ans81.c){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.d>this.company.stavoviZaposlenih.ans81.e){
    tempCounterRank-=1;
  }
  this.rankovi[3]=tempCounterRank;
  tempCounterRank=5;

  /////////////////////

  if(this.company.stavoviZaposlenih.ans81.e>this.company.stavoviZaposlenih.ans81.a){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.e>this.company.stavoviZaposlenih.ans81.b){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.e>this.company.stavoviZaposlenih.ans81.c){
    tempCounterRank-=1;
  }
  if(this.company.stavoviZaposlenih.ans81.e>this.company.stavoviZaposlenih.ans81.d){
    tempCounterRank-=1;
  }
  this.rankovi[4]=tempCounterRank;
  tempCounterRank=5;

  },

  methods:{
     makePDF1(){


      window.html2canvas=html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("l","pt","a4");
      doc.html(document.querySelector("#staz"),{
        callback: function(pdf){
          pdf.save("mypdf.pdf");
        }
      });
    },
    makePDF2(){


     window.html2canvas=html2canvas;
     //p = portrait (moze i l za landscape)
     //pt za measurement
     //a4 za format
     var doc = new jsPDF("l","pt","a4");
     doc.html(document.querySelector("#pol"),{
       callback: function(pdf){
         pdf.save("mypdf.pdf");
       }
     });
   },
   makePDFGroup(){


    window.html2canvas=html2canvas;
    //p = portrait (moze i l za landscape)
    //pt za measurement
    //a4 za format
    var doc = new jsPDF("l","pt","a4");
    doc.html(document.querySelector("#groupPDF"),{
      callback: function(pdf){
        pdf.save("mypdf.pdf");
      }
    });
  },
  },
  mounted(){
      Chart.plugins.register({
     beforeDraw: function(stazGraph) {
    var ctx = stazGraph.chart.ctx;
    if(stazGraph.config.options.plug == true){
    ctx.fillStyle = "#dddddd";//dddddd
    ctx.fillRect(0, 0, stazGraph.chart.width, stazGraph.chart.height);
  }
  }
});
  }
}
</script>

<style lang="css" scoped>
.yesnoQuestion {

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "yesnoH1 yesnoH1"
    "yesnoTable yesnoGraph"
    "yesnoTable yesnoGraph"
    "yesnoTable yesnoGraph"
    "yesnoTable yesnoGraph";
    background-color: #dddddd;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
    width: 800px;
    height: 580px;

}
.yesnoH1 { grid-area: yesnoH1; }
.yesnoTable { grid-area: yesnoTable; }
.yesnoGraph { grid-area: yesnoGraph; }

.stringquestion {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "stringH1 stringH1"
    "stringField stringField"
    "stringField stringField"
    "stringField stringField"
    "stringField stringField";
    background-color: #dddddd;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
  width: 800px;
  height: 580px;
}
.stringH1 { grid-area: stringH1; }
.stringField { grid-area: stringField; }
.graf{
  grid-area: graf;
}
#pol{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "polH1 polH1"
    "polTable polGraph"
    "polTable polGraph"
    "polTable polGraph"
    "polTable polGraph";
    background-color: #dddddd;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
    width: 800px;
    height: 575px;
}
.polH1 { grid-area: polH1; }
.polTable { grid-area: polTable; }
.polGraph { grid-area: polGraph; }
#staz{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "stazH1 stazH1"
    "stazTable stazGraph"
    "stazTable stazGraph"
    "stazTable stazGraph"
    "stazTable stazGraph";
    background-color: #dddddd;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
    width: 800px;
    height: 575px;

}
table{
  border: 2px solid black;
  text-align: center;
}
td, th{
  border: 1px solid black;
  text-align: center;
  padding: 5px;
}
tr:nth-child(even){background-color: #aaaacc;}
tr:nth-child(odd){background-color: #ccccff;}
tr:hover {background-color: #afa;}
/* table, th, td{
  border: 1px solid black;
  text-align: center;
}
tr:nth-child(even){background-color: #bbbbbb;}
tr:hover {background-color: #afa;} */
.stazH1 { grid-area: stazH1; }
.stazTable { grid-area: stazTable; }
#stazGraph { grid-area: stazGraph;
}
.demografija{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
}
#izvestaj{
  width: 800px;
  height: 580px;
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding-top: 235px;
  text-align: center;
}
.korpKultura{
  width: 800px;
  height: 584px;
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding-top: 235px;
  text-align: center;


}

.korpKultura2{
  width: 800px;
  height: 584px;
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding-top: 235px;
  text-align: center;
  margin-top: -10px;


}
#korpH1{

}
.medjuljudskiOdnosi{

}
h1{
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

}

</style>
